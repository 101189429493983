import React, { lazy, Suspense, useEffect, useState } from 'react';
import { headerProps } from './types';
import clsx from 'clsx';
import '../Sidebar/Sidebar.css';
import {
	AppBar,
	Search,
	SearchIconWrapper,
	StyledInputBase,
} from '../Sidebar/SidebarStyles';
import { headerStyles } from './HeaderStyles';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, Toolbar, Typography } from '@mui/material';
import './header.css';
import { InputChangeEvent } from '../../../AppUtils/Utils/globalTypes';
import {
	useAppDispatch,
	useAppSelector,
} from '../../../AppUtils/Utils/appHooks';
import { searchData } from '../../../Pages/CommonAppRedux/CommonAppSlice';
import { commonAppSelector } from '../../../Pages/CommonAppRedux/selector';
import getCookie from '../../../AppUtils/Utils/Cookies/getCookie';
import { notificationWSConnection } from '../../../AppUtils/Utils/ws';

const Header = ({ open, setOpen }: headerProps) => {
	const handleDrawer = () => {
		setOpen(!open);
	};
	const { search } = useAppSelector(commonAppSelector);
	const [accessToken, setAccessToken] = useState<string | undefined>('');

	const dispatch = useAppDispatch();
	const handleSearch = ({ target: { value } }: InputChangeEvent) => {
		dispatch(searchData(value));
		// setSearch(e?.target?.value.toLowerCase());
	};

	useEffect(() => {
		const token = getCookie('accessToken');
		setAccessToken(token);
	}, []);

	useEffect(() => {
		dispatch(searchData(''));
	}, [dispatch]);

	useEffect(() => {
		if (accessToken) {
			notificationWSConnection(accessToken)
		}
	}, [accessToken]);

	const classes = headerStyles();
	return (
		<>
			<AppBar position="fixed" open={open} className="secondary-header">
				<Toolbar className={classes.toolbar}>
					<div>
						<Typography
							component="h1"
							variant="h4"
							color="inherit"
							noWrap
							className={classes.title}>
							<IconButton
								className={clsx(
									classes.menuButton,
									!open && classes.menuButtonHidden
								)}
								onClick={handleDrawer}>
								<MenuIcon />
							</IconButton>
						</Typography>
					</div>
					<div
						className={classes.searchAppbar}
						id="main-searchBar-container">
						<Search className="search-wrapper">
							<StyledInputBase
								value={search}
								className="search-wrapper-input"
								placeholder="Search..."
								inputProps={{ 'aria-label': 'search' }}
								onChange={handleSearch}
							/>
							<SearchIconWrapper className="search-icon-wrapper">
								<SearchIcon />
							</SearchIconWrapper>
						</Search>
					</div>
				</Toolbar>
			</AppBar>
		</>
	);
};
export default React.memo(Header);
