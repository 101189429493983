import { mergeMap } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Action } from '@reduxjs/toolkit';
import { combineEpics } from 'redux-observable';
import {
	dispatchAction,
	stateAction,
} from '../../../AppUtils/Utils/globalTypes';
import {
	getAllNotificationFail,
	getAllNotificationRequest,
	getAllNotificationSuccess,
	getNotificationCountFail,
	getNotificationCountRequest,
	getNotificationCountSuccess,
	getNotificationFail,
	getNotificationRequest,
	getNotificationSuccess,
	readAllNotificationFail,
	readAllNotificationRequest,
	readAllNotificationSuccess,
	readNotificationFail,
	readNotificationRequest,
	readNotificationSuccess,
} from './notificationSlice';
import {
	getAllNotification,
	getNotificationCount,
	getNotifications,
	readAll,
	readNotification,
} from './api';

// get Notification
const getNotificationEpic = (
	action$: Observable<Action>,
	_: stateAction,
	{ dispatch }: dispatchAction
) =>
	action$.pipe(
		filter(getNotificationRequest.match),
		mergeMap(async ({ payload }) => {
			try {
				const response = await getNotifications();
				return { payload: response.data };
			} catch (error) {
				return { error };
			}
		}),
		map(({ payload }) =>
			payload ? getNotificationSuccess(payload) : getNotificationFail()
		)
	);

// get Notification count
const getNotificationCountEpic = (
	action$: Observable<Action>,
	_: stateAction,
	{ dispatch }: dispatchAction
) =>
	action$.pipe(
		filter(getNotificationCountRequest.match),
		mergeMap(async () => {
			try {
				const response = await getNotificationCount();
				return { payload: response.data };
			} catch (error) {
				return { error };
			}
		}),
		map(({ payload }) =>
			payload
				? getNotificationCountSuccess(payload)
				: getNotificationCountFail()
		)
	);

// All Notification
const getAllNotificationEpic = (
	action$: Observable<Action>,
	_: stateAction,
	{ dispatch }: dispatchAction
) =>
	action$.pipe(
		filter(getAllNotificationRequest.match),
		mergeMap(async () => {
			try {
				const response = await getAllNotification();
				return { payload: response.data };
			} catch (error) {
				return { error };
			}
		}),
		map(({ payload }) =>
			payload
				? getAllNotificationSuccess(payload)
				: getAllNotificationFail()
		)
	);

// read Notification
const readNotificationEpic = (
	action$: Observable<Action>,
	_: stateAction,
	{ dispatch }: dispatchAction
) =>
	action$.pipe(
		filter(readNotificationRequest.match),
		mergeMap(async ({ payload: { id } }) => {
			try {
				const body = JSON.stringify({ id });
				const response = await readNotification(body);
				if (response) {
					dispatch(getNotificationRequest());
					dispatch(getNotificationCountRequest());
				}
				return { payload: response.data };
			} catch (error) {
				return { error };
			}
		}),
		map(({ payload }) =>
			payload ? readNotificationSuccess(payload) : readNotificationFail()
		)
	);
// read all Notification
const readAllNotificationEpic = (
	action$: Observable<Action>,
	_: stateAction,
	{ dispatch }: dispatchAction
) =>
	action$.pipe(
		filter(readAllNotificationRequest.match),
		mergeMap(async () => {
			try {
				const response = await readAll();
				if (response) {
					dispatch(getNotificationRequest());
					dispatch(getNotificationCountRequest());
				}
				return { payload: response.data };
			} catch (error) {
				return { error };
			}
		}),
		map(({ payload }) =>
			payload
				? readAllNotificationSuccess(payload)
				: readAllNotificationFail()
		)
	);
export const notificationEpics = combineEpics(
	getNotificationEpic,
	getNotificationCountEpic,
	getAllNotificationEpic,
	readAllNotificationEpic,
	readNotificationEpic
);
