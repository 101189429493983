import { createSlice } from '@reduxjs/toolkit';
import { initialStateCompany } from './types';
const initialState: initialStateCompany = {
	companies: [],
	loadingCompany: false,
	count: 0,
	previous: '',
	next: '',
	// update
	edit: true,
	loadingUpdateCompany: false,
	company: null,
	loadingUpdateStatus: false,
	editStatus: false,
	// details
	loadingDetails: false,
	isViewDetails: false,
	//category
	categories: [],
	loadingCompanyCategory: false,
	//subCategory
	subCategories: [],
	loadingCompanySubCategory: false,
	//category sub type
	categorySubTypes: [],
	loadingCompanyCategorySubType: false,
	//sector
	sectors: [],
	loadingCompanySector: false,
	categoriesValue: null,
};

export const Company = createSlice({
	name: 'CompanyReducer',
	initialState,
	reducers: {
		setCategoriesValue: (state, {payload})=>{
			state.categoriesValue = payload
		},
		loadingCompany: (state) => {
			state.loadingCompany = true;
		},
		getCompanyRequest: (state, payload) => {
			state.loadingCompany = true;
		},
		getCompanySuccess: (
			state,
			{ payload: { results, count, next, previous } }
		) => {
			state.loadingCompany = false;
			state.companies = results;
			state.count = count;
			state.next = next;
			state.previous = previous;
		},
		getCompanyFail: (state) => {
			state.loadingCompany = false;
			state.companies = [];
		},
		getCompanyNextRequest: (state, payload) => {
			state.loadingCompany = true;
		},
		getCompanyPreviousRequest: (state, payload) => {
			state.loadingCompany = true;
		},
		// details
		getCompanyDetailsRequest: (state, payload) => {
			state.loadingDetails = true;
			state.isViewDetails = true;
			state.edit = false;
		},
		getCompanyDetailsSuccess: (state, { payload }) => {
			state.loadingDetails = false;
			state.company = payload;
			state.isViewDetails = true;
		},
		getCompanyDetailsFail: (state) => {
			state.isViewDetails = false;
			state.loadingDetails = false;
		},
		getCompanyDetails: (state, { payload }) => {
			state.editStatus = true;
			state.company = payload;
		},
		//
		getCompanyByIdRequest: (state, payload) => {
			state.edit = true;
			state.isViewDetails = false;
		},
		editCompanySuccess: (state, { payload }) => {
			state.company = payload;
		},
		editCompanyFail: (state) => {
			state.edit = false;
		},
		updateCompanyRequest: (state, payload) => {
			state.loadingUpdateCompany = true;
		},
		updateCompanySuccess: (state) => {
			state.loadingUpdateCompany = false;
		},
		updateCompanyFail: (state) => {
			state.loadingUpdateCompany = false;
		},
		// update status
		updateStatusRequest: (state, payload) => {
			state.loadingUpdateStatus = true;
		},
		updateStatusSuccess: (state) => {
			state.loadingUpdateStatus = false;
		},
		updateStatusFail: (state) => {
			state.loadingUpdateStatus = false;
		},
		//category
		loadingCompanyCategory: (state) => {
			state.loadingCompanyCategory = true;
		},
		getCompanyCategoryRequest: (state) => {
			state.loadingCompanyCategory = true;
		},
		getCompanyCategorySuccess: (state, { payload: { results } }) => {
			state.loadingCompanyCategory = false;
			state.categories = results;
		},
		getCompanyCategoryFail: (state) => {
			state.loadingCompanyCategory = false;
		},
		//sub category
		loadingCompanySubCategory: (state) => {
			state.loadingCompanySubCategory = true;
		},
		getCompanySubCategoryRequest: (state) => {
			state.loadingCompanySubCategory = true;
		},
		getCompanySubCategorySuccess: (state, { payload: { results } }) => {
			state.loadingCompanySubCategory = false;
			state.subCategories = results;
		},
		getCompanySubCategoryFail: (state) => {
			state.loadingCompanySubCategory = false;
		},
		//category sub type
		loadingCompanyCategorySubType: (state) => {
			state.loadingCompanyCategorySubType = true;
		},
		getCompanyCategorySubTypeRequest: (state) => {
			state.loadingCompanyCategorySubType = true;
		},
		getCompanyCategorySubTypeSuccess: (state, { payload: { results } }) => {
			state.loadingCompanyCategorySubType = false;
			state.categorySubTypes = results;
		},
		getCompanyCategorySubTypeFail: (state) => {
			state.loadingCompanyCategorySubType = false;
		},
		//category sectors
		loadingCompanySector: (state) => {
			state.loadingCompanySector = true;
		},
		getCompanySectorRequest: (state) => {
			state.loadingCompanySector = true;
		},
		getCompanySectorSuccess: (state, { payload: { results } }) => {
			state.loadingCompanySector = false;
			state.sectors = results;
		},
		getCompanySectorFail: (state) => {
			state.loadingCompanySector = false;
		},

		// clear data
		clearCompanyData: (state) => {
			state.company = null;
			state.editStatus = false;
		},
	},
});
// Action creators are generated for each case reducer function
export const {
	setCategoriesValue,
	loadingCompany,
	getCompanySuccess,
	getCompanyFail,
	getCompanyRequest,
	getCompanyNextRequest,
	getCompanyPreviousRequest,
	//
	clearCompanyData,
	getCompanyDetails,
	// edit
	getCompanyByIdRequest,
	editCompanySuccess,
	editCompanyFail,
	//
	updateCompanyRequest,
	updateCompanySuccess,
	updateCompanyFail,
	// details
	getCompanyDetailsRequest,
	getCompanyDetailsSuccess,
	getCompanyDetailsFail,
	// update status
	updateStatusRequest,
	updateStatusSuccess,
	updateStatusFail,
	//category
	loadingCompanyCategory,
	getCompanyCategoryRequest,
	getCompanyCategorySuccess,
	getCompanyCategoryFail,
	//category subtype
	loadingCompanyCategorySubType,
	getCompanyCategorySubTypeRequest,
	getCompanyCategorySubTypeSuccess,
	getCompanyCategorySubTypeFail,
	//sub category
	loadingCompanySubCategory,
	getCompanySubCategoryRequest,
	getCompanySubCategorySuccess,
	getCompanySubCategoryFail,
	//sector
	loadingCompanySector,
	getCompanySectorRequest,
	getCompanySectorSuccess,
	getCompanySectorFail,
} = Company.actions;
export default Company.reducer;
