import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const subCategoryModURL = "api/v1/category-app/sub-category";

//get Category data api
export const getSubCategory = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${subCategoryModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get Sub Category by Id
export const getSubCategoryById = (id: number) => {
  return axiosInstance.get(`${subCategoryModURL}/${id}`);
}

//view SubCategory by Id
export const viewSubCategoryById = (id: number) => {
  return axiosInstance.get(`${subCategoryModURL}/${id}`);
}

//handle search
export const searchSubCategory = ({ rowsPerPage, search }: searchProps) => {
  return `${subCategoryModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create Category
// export const createCategory = (body: string | FormData) =>
//   axiosInstance.post(`${categoryModURL}`, body);
// update sub Category
export const updateSubCategory = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${subCategoryModURL}/${id}`, body);
