import {
  getSectorSuccess,
  getSectorFail,
  loadingSector,
  getSectorRequest,
  //
  getSectorNextRequest,
  getSectorPreviousRequest,
  //
  getSectorByIdRequest,
  editSectorSuccess,
  editSectorFail,
  //
  clearSectorData,
  //
  updateSectorRequest,
  updateSectorSuccess,
  updateSectorFail,
  //
} from "./sectorSetupSlice";
import { mergeMap } from "rxjs";
import { getSector, getSectorById, updateSector } from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import { alertErrorAction, alertSuccessAction, closeModal } from "../../../CommonAppRedux/CommonAppSlice";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get Sector
const getSectorEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSectorRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingSector());
      try {
        const response = await getSector(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getSectorSuccess(action?.payload) : getSectorFail()
    )
  );

//get sector by id
const getSectorByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSectorByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getSectorById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? editSectorSuccess(action?.payload) : editSectorFail()
    )
  );

//get next
const getSectorNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSectorNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getSectorSuccess(action?.payload) : getSectorFail()
    )
  );

//get previous
const getSectorPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSectorPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getSectorSuccess(action?.payload) : getSectorFail()
    )
  );

//update sector epic
const updateSectorEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateSectorRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      // const {
      //   name,
      //   is_featured,
      //   remarks,
      //   image,
      //   sub_category_name,
      //   is_active,
      // } = values;
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }
        // if(image) {
        //   body.append("image", image)
        // }
        // body.append("name", name);
        // body.append("is_featured", is_featured);
        // body.append("is_active", is_active);
        // body.append("remarks", remarks);
        // body.append("sub_category_name", sub_category_name);
        const response = await updateSector(body, id);
        if (response) {
          dispatch(getSectorRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearSectorData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateSectorSuccess() : updateSectorFail()
    )
  );

export const sectorEpics = combineEpics(
  getSectorEpic,
  getSectorNext,
  getSectorPrevious,
  getSectorByIdEpic,
  updateSectorEpic,
);
