import { Action } from '@reduxjs/toolkit';
import {
	categorySubTypeWise,
	categoryWise,
	companyWise,
	listingTypeWise,
	sectorWise,
	statusWise,
	subCategoryWise,
	userTypeWise,
} from './api';
import {
	getCategorySubTypeWiseFail,
	getCategorySubTypeWiseRequest,
	getCategorySubTypeWiseSuccess,
	getCategoryWiseFail,
	getCategoryWiseRequest,
	getCategoryWiseSuccess,
	getCompanyWiseFail,
	getCompanyWiseRequest,
	getCompanyWiseSuccess,
	getListingTypeWiseFail,
	getListingTypeWiseRequest,
	getListingTypeWiseSuccess,
	getSectorWiseFail,
	getSectorWiseRequest,
	getSectorWiseSuccess,
	getStatusWiseFail,
	getStatusWiseRequest,
	getStatusWiseSuccess,
	getSubCategoryWiseFail,
	getSubCategoryWiseRequest,
	getSubCategoryWiseSuccess,
	getUserTypeWiseFail,
	getUserTypeWiseRequest,
	getUserTypeWiseSuccess,
} from './dashboardSlice';
import { Observable, filter, map, mergeMap } from 'rxjs';
import {
	dispatchAction,
	stateAction,
} from '../../../../AppUtils/Utils/globalTypes';
import { combineEpics } from 'redux-observable';

// get company epic
const getCompanyWiseEpic = (
	action$: Observable<Action>,
	_: stateAction,
	{ dispatch }: dispatchAction
) =>
	action$.pipe(
		filter(getCompanyWiseRequest.match),
		mergeMap(async ({ payload }) => {
			try {
				const response = await companyWise(payload);
				return { payload: response.data };
			} catch (error) {
				return { error };
			}
		}),
		map(({ payload }) =>
			payload ? getCompanyWiseSuccess(payload) : getCompanyWiseFail()
		)
	);
// get category subtype wise epic
const getCategorySubTypeEpic = (
	action$: Observable<Action>,
	_: stateAction,
	{ dispatch }: dispatchAction
) =>
	action$.pipe(
		filter(getCategorySubTypeWiseRequest.match),
		mergeMap(async ({ payload }) => {
			try {
				const response = await categorySubTypeWise();
				return { payload: response.data };
			} catch (error) {
				return { error };
			}
		}),
		map(({ payload }) =>
			payload
				? getCategorySubTypeWiseSuccess(payload)
				: getCategorySubTypeWiseFail()
		)
	);
// get category wise epic
const getCategoryWiseEpic = (
	action$: Observable<Action>,
	_: stateAction,
	{ dispatch }: dispatchAction
) =>
	action$.pipe(
		filter(getCategoryWiseRequest.match),
		mergeMap(async ({ payload }) => {
			try {
				const response = await categoryWise();
				return { payload: response.data };
			} catch (error) {
				return { error };
			}
		}),
		map(({ payload }) =>
			payload ? getCategoryWiseSuccess(payload) : getCategoryWiseFail()
		)
	);
// get listing type wise epic
const getListingTypeWiseEpic = (
	action$: Observable<Action>,
	_: stateAction,
	{ dispatch }: dispatchAction
) =>
	action$.pipe(
		filter(getListingTypeWiseRequest.match),
		mergeMap(async ({ payload }) => {
			try {
				const response = await listingTypeWise();
				return { payload: response.data };
			} catch (error) {
				return { error };
			}
		}),
		map(({ payload }) =>
			payload
				? getListingTypeWiseSuccess(payload)
				: getListingTypeWiseFail()
		)
	);
// get sector wise epic
const getSectorWiseEpic = (
	action$: Observable<Action>,
	_: stateAction,
	{ dispatch }: dispatchAction
) =>
	action$.pipe(
		filter(getSectorWiseRequest.match),
		mergeMap(async ({ payload }) => {
			try {
				const response = await sectorWise();
				return { payload: response.data };
			} catch (error) {
				return { error };
			}
		}),
		map(({ payload }) =>
			payload ? getSectorWiseSuccess(payload) : getSectorWiseFail()
		)
	);
// get user type wise epic
const getUserTypeWiseEpic = (
	action$: Observable<Action>,
	_: stateAction,
	{ dispatch }: dispatchAction
) =>
	action$.pipe(
		filter(getUserTypeWiseRequest.match),
		mergeMap(async ({ payload }) => {
			try {
				const response = await userTypeWise();
				return { payload: response.data };
			} catch (error) {
				return { error };
			}
		}),
		map(({ payload }) =>
			payload ? getUserTypeWiseSuccess(payload) : getUserTypeWiseFail()
		)
	);
// get sub category wise epic
const getSubCategoryWiseEpic = (
	action$: Observable<Action>,
	_: stateAction,
	{ dispatch }: dispatchAction
) =>
	action$.pipe(
		filter(getSubCategoryWiseRequest.match),
		mergeMap(async ({ payload }) => {
			try {
				const response = await subCategoryWise();
				return { payload: response.data };
			} catch (error) {
				return { error };
			}
		}),
		map(({ payload }) =>
			payload
				? getSubCategoryWiseSuccess(payload)
				: getSubCategoryWiseFail()
		)
	);
// get status wise epic
const getStatusWiseEpic = (
	action$: Observable<Action>,
	_: stateAction,
	{ dispatch }: dispatchAction
) =>
	action$.pipe(
		filter(getStatusWiseRequest.match),
		mergeMap(async ({ payload }) => {
			try {
				const response = await statusWise();
				return { payload: response.data };
			} catch (error) {
				return { error };
			}
		}),
		map(({ payload }) =>
			payload ? getStatusWiseSuccess(payload) : getStatusWiseFail()
		)
	);

export const dashboardEpics = combineEpics(
	getCompanyWiseEpic,
	getSubCategoryWiseEpic,
	getCategorySubTypeEpic,
	getCategoryWiseEpic,
	getListingTypeWiseEpic,
	getSectorWiseEpic,
	getUserTypeWiseEpic,
	getStatusWiseEpic
);
