import {
  createProfessionalFail,
  createProfessionalRequest,
  createProfessionalSuccess,
  //
  getProfessionalRequest,
  getProfessionalFail,
  getProfessionalSuccess,
  //
  updateProfessionalRequest,
  updateProfessionalFail,
  updateProfessionalSuccess,
  //
  getProfessionalNextRequest,
  getProfessionalPreviousRequest,
  //
  clearProfessionalData,
  getProfessionalByIdRequest,
  professionalEditSuccess,
  professionalEditFail,
} from "./professionalSetupSlice";
import { mergeMap } from "rxjs";
import {
  createProfessional,
  getProfessional,
  getProfessionalById,
  getProfessionalTag,
  updateProfessional,
  getProfessionalCategory,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get professional epic
const getProfessionalEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getProfessionalRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getProfessional(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getProfessionalSuccess(action?.payload)
        : getProfessionalFail()
    )
  );

//get professional by id
const getProfessionalByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getProfessionalByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getProfessionalById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? professionalEditSuccess(action?.payload)
        : professionalEditFail()
    )
  );

//get next
const getProfessionalNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getProfessionalNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getProfessionalSuccess(action?.payload)
        : getProfessionalFail()
    )
  );

//get previous
const getProfessionalPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getProfessionalPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getProfessionalSuccess(action?.payload)
        : getProfessionalFail()
    )
  );

//create professional epic
const createProfessionalEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createProfessionalRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      const {
        full_name,
        company_name,
        designation,
        license_number,
        email,
        alternate_mobile_no,
        mobile_no,
        about,
        is_active,
        // slug,
        image,
      } = values;
      try {
        const body = new FormData();

        if (image) {
          body.append("image", image);
        }
        body.append("full_name", full_name);
        body.append("company_name", company_name);
        body.append("designation", designation);
        body.append("license_number", license_number);
        body.append("email", email);
        body.append("alternate_mobile_no", alternate_mobile_no);
        body.append("mobile_no", mobile_no);
        body.append("about", about);
        body.append("is_active", is_active);
        // body.append("slug", slug);
        

        const response = await createProfessional(body);
        if (response) {
          dispatch(getProfessionalRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createProfessionalSuccess()
        : createProfessionalFail();
    })
  );

//update professional epic
const updateProfessionalEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateProfessionalRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      const {
        full_name,
        company_name,
        designation,
        license_number,
        email,
        alternate_mobile_no,
        mobile_no,
        about,
        is_active,
        slug,
        image,
        remarks,
      } = values;

      try {
        let body = new FormData();
        if(image) {
        body.append("image", image);
        }
        body.append("full_name", full_name);
        body.append("company_name", company_name);
        body.append("designation", designation);
        body.append("license_number", license_number);
        body.append("email", email);
        body.append("alternate_mobile_no", alternate_mobile_no);
        body.append("mobile_no", mobile_no);
        body.append("about", about);
        body.append("is_active", is_active);
        body.append("slug", slug);
        body.append("remarks", remarks);

        const response = await updateProfessional(body, slug);
        if (response) {
          dispatch(getProfessionalRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearProfessionalData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateProfessionalSuccess() : updateProfessionalFail()
    )
  );

export const professionalEpics = combineEpics(
  getProfessionalEpic,
  createProfessionalEpic,
  updateProfessionalEpic,
  getProfessionalNext,
  getProfessionalPrevious,
  getProfessionalByIdEpic
);
