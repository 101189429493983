import { axiosInstance } from '../../../AppUtils/Utils/axios';
import { defaultRowsPerPage } from '../../../AppUtils/Utils/globalConstant';
import { getApiProps } from '../../../AppUtils/Utils/globalTypes';

export const notificationURL = '/api/v1/notification-app';

export const getNotifications = () => {
	return axiosInstance.get(
		`${notificationURL}/user-notification?offset=0&limit=${defaultRowsPerPage}?ordering=-created_date_ad`
	);
};

//obtaining all data
export const getAllNotification = () => {
	return axiosInstance.get(
		`${notificationURL}/user-notification?ordering=-created_date_ad`
	);
};

// get notification count
export const getNotificationCount = () => {
	return axiosInstance.get(`${notificationURL}/user-notification-count`);
};

// read notification
export const readNotification = (body: any) => {
	return axiosInstance.post(
		`${notificationURL}/user-notification/read`,
		body
	);
};

// read all notification
export const readAll = () => {
	return axiosInstance.get(`${notificationURL}/user-notification/read-all`);
};


