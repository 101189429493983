import { Card, CardContent, Divider, Grid } from "@mui/material";
import React, { useEffect, useState, memo } from "react";
import { useDispatch } from "react-redux";
//
import { useAppSelector } from "../../AppUtils/Utils/appHooks";
import {
  showAppModal,
  showReferenceRangeModal,
} from "../../Pages/CommonAppRedux/CommonAppSlice";
import AppButton from "../AppElements/Button/AppButton";
import { commonAppSelector } from "../../Pages/CommonAppRedux/selector";
import FixedAppBar from "./Header/FixedHeader";
import Header from "./Header/Header";
import SidebarMenu from "./Sidebar/SidebarMenu";
import { useStyles, Main } from "./Sidebar/SidebarStyles";
import AppTabs from "../AppTabs/Tabs";
import { masterSidebarData } from "./Sidebar/MasterSidebarData";
import { useImmer } from "use-immer";
import { loginSelector } from "../../Pages/Login/Redux/selector";
import { handlePermission } from "../../AppUtils/Utils/permisison";
interface layoutProps {
  children: React.ReactNode;
}
const Layout = ({ children }: layoutProps) => {
  const {
    selectedModuleIndex,
    hideAddButton,
    hideTabAndAction,
    hideCard,
    passPermission,
  } = useAppSelector(commonAppSelector);
  const dispatch = useDispatch();
  // useEffect(() => {
  //     setOpenSidebar((draft) => {
  //         draft.openMasterSidebar = true;
  //       });
  // }, []);
  const { permissions, is_superuser } = useAppSelector(loginSelector);

  const [open, setOpen] = useState(true);
  const classes = useStyles();
  const handleClickOpen = () => {
    dispatch(showAppModal(true));
    dispatch(showReferenceRangeModal());
  };
  // data to be load in sidebar
  // let dataToBeLoad;
  // if(openMasterSidebar) {
  //   dataToBeLoad = masterSidebarData;
  // }
  // const dataToBeLoad = openMasterSidebar
  //   ? masterSidebarData : "";
  //event stop default event
  // document.addEventListener("keydown", (e) => {
  //   if (
  //     e.key === "s" &&
  //     (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)
  //   ) {
  //     e.preventDefault();
  //   }
  // });
  // document.addEventListener("keydown", (e) => {
  //   if (
  //     e.key === "p" &&
  //     (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)
  //   ) {
  //     e.preventDefault();
  //   }
  // });
  //event
  return (
    <>
      <FixedAppBar openSidebar={true} setOpenSidebar={true} />
      <Header open={open} setOpen={setOpen} />
      <SidebarMenu
        open={open}
        dataToBeLoad={masterSidebarData}
        setOpen={setOpen}
      />
      <Main open={open}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* {!hideCard && ( */}
            <Card>
              {!hideTabAndAction && (
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  className={classes.cardHeader}
                >
                  <Grid item>
                    <AppTabs dataToBeLoad={masterSidebarData as any} />
                  </Grid>
                  {is_superuser ||
                  handlePermission(
                    permissions,
                    passPermission?.add_permission
                  ) ? (
                    <>
                      {!hideAddButton && (
                        <Grid item>
                          <AppButton
                            tooltipTitle="Add"
                            submit={true}
                            onClick={handleClickOpen}
                          >
                            Add
                          </AppButton>
                        </Grid>
                      )}
                    </>
                  ) : null}
                </Grid>
              )}
              <Divider />
              <CardContent className={classes.cardContent}>
                {children}
              </CardContent>
            </Card>
            {/* )} */}
          </Grid>
        </Grid>
      </Main>
    </>
  );
};

export default memo(Layout);
