import {
  createBlogCategorySuccess,
  createBlogCategoryFail,
  createBlogCategoryRequest,
  getBlogCategorySuccess,
  getBlogCategoryFail,
  loadingBlogCategory,
  updateBlogCategoryFail,
  updateBlogCategorySuccess,
  getBlogCategoryRequest,
  updateBlogCategoryRequest,
  getBlogCategoryNextRequest,
  getBlogCategoryPreviousRequest,
  clearBlogCategoryData,
  getBlogCategoryByIdRequest,
  categoryBlogEditFail,
  categoryBlogEditSuccess,
} from "./categorySlice";
import { mergeMap } from "rxjs";
import {
  createCategory,
  getCategory,
  getCategoryById,
  updateCategory,
} from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get Category
const getCategoryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBlogCategoryRequest.match),
    mergeMap(async (action) => {
      // dispatch(loadingBlogCategory());
      try {
        const response = await getCategory(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getBlogCategorySuccess(action?.payload)
        : getBlogCategoryFail()
    )
  );

//get Category By Id epic
const getCategoryByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBlogCategoryByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getCategoryById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { errors: e };
      }
    }),
    map((action) =>
      action?.payload
        ? categoryBlogEditSuccess(action.payload)
        : categoryBlogEditFail()
    )
  );

//get next
const getCategoryNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBlogCategoryNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getBlogCategorySuccess(action?.payload)
        : getBlogCategoryFail()
    )
  );

//get previous
const getCategoryPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBlogCategoryPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getBlogCategorySuccess(action?.payload)
        : getBlogCategoryFail()
    )
  );
//create Category epic
const createCategoryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createBlogCategoryRequest.match),
    mergeMap(
      async ({
        payload: { values, rowsPerPage, page, setShowModalFromAnotherModule },
      }) => {
        const { name } = values;
        try {
          const body = JSON.stringify({ name });
          const response = await createCategory(body);
          if (response) {
            dispatch(getBlogCategoryRequest({ rowsPerPage, page }));
            dispatch(alertSuccessAction(messages.createMessage));
            setShowModalFromAnotherModule
              ? setShowModalFromAnotherModule(false)
              : dispatch(closeModal());
          }
          return { payload: { response } };
        } catch (e) {
          dispatch(alertErrorAction(messages.createFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) => {
      return action?.payload
        ? createBlogCategorySuccess()
        : createBlogCategoryFail();
    })
  );

//update Category epic
const updateCategoryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateBlogCategoryRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      const { name, remarks } = values;
      try {
        const body = JSON.stringify({
          name,
          remarks,
        });
        const response = await updateCategory(body, id);
        if (response) {
          dispatch(getBlogCategoryRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(clearBlogCategoryData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateBlogCategorySuccess() : updateBlogCategoryFail()
    )
  );

export const blogCategoryEpics = combineEpics(
  getCategoryEpic,
  createCategoryEpic,
  updateCategoryEpic,
  getCategoryNext,
  getCategoryPrevious,
  getCategoryByIdEpic
);
