import React, { memo, useEffect } from "react";
import TooltipContent from "../../Tooltip/TooltipContent";
import Spinner from "../../Loader/Spinner";
import { useImmer } from "use-immer";

const AppButton = ({
  className,
  tooltipTitle,
  disabled,
  title,
  loading,
  content,
  color,
  onClick,
  reference,
  id,
  children,
  submit,
  isDelete,
  name,
}: any) => {
  const [setEvent, ShowSetEvent] = useImmer(true);
  // useEffect(() => {
  //   if (id === "saveBtn" && loading === false && setEvent) {
  //     document.addEventListener(
  //       "keyup",
  //       function (event) {
  //         if (event.ctrlKey && event.code === "KeyS" && loading === false) {
  //           // @ts-ignore
  //           var elem = document.getElementById("saveBtn").click();
  //           ShowSetEvent(false);
  //         }
  //       },
  //       false
  //     );
  //   }
  //   if (id === "saveAndPrintBtn") {
  //     document.addEventListener(
  //       "keyup",
  //       function (event) {
  //         if (event.ctrlKey && event.code === "KeyP") {
  //           // @ts-ignore
  //           var elem = document.getElementById("saveAndPrintBtn").click();
  //         }
  //       },
  //       false
  //     );
  //   }
  //   if (id === "addBtn") {
  //     document.addEventListener(
  //       "keyup",
  //       function (event) {
  //         if (event.ctrlKey && event.code === "Enter") {
  //           // @ts-ignore
  //           var elem = document.getElementById("addBtn").click();
  //           // @ts-ignore
  //         }
  //       },
  //       false
  //     );
  //   }
  // }, [id]);

  const checkTitleToDisplayAdd = ["Pack", "Item"];
  return (
    <TooltipContent title={tooltipTitle ? tooltipTitle : title ? title : ""}>
      <button
        name={name ?? tooltipTitle}
        type={submit ? "submit" : "button"}
        id={id}
        onClick={onClick}
        disabled={disabled}
        className={
          className
            ? className
            : submit
            ? "submit_button"
            : isDelete
            ? "delete_button"
            : "default_button"
        }
        ref={reference}
      >
        <span>{loading ? <Spinner color={color} /> : title}</span>
        {`${checkTitleToDisplayAdd.includes(title) ? "+" : ""}`}
        {children}
      </button>
    </TooltipContent>
  );
};
export default memo(AppButton);
