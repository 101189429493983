import {createSlice } from "@reduxjs/toolkit";
import {initialStateCategorySubType } from "./types";
const initialState: initialStateCategorySubType = {
  categoriesSubType: [],
  loadingCategorySubType: false,
  edit: false,
  categorySubType: null,
  loadingUpdateCategorySubType: false,
  count: 0,
  previous: "",
  next: "",
};

export const CategorySubType = createSlice({
  name: "CategorySubTypeReducer",
  initialState,
  reducers: {
    loadingCategorySubType: (state) => {
      state.loadingCategorySubType = true;
    },
    getCategorySubTypeRequest: (state, payload) => {
      state.loadingCategorySubType = true;
    },
    getCategorySubTypeSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingCategorySubType = false;
      state.categoriesSubType = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getCategorySubTypeFail: (state) => {
      state.loadingCategorySubType = false;
    },
    getCategorySubTypeNextRequest: (state, payload) => {
      state.loadingCategorySubType = true;
    },
    getCategorySubTypePreviousRequest: (state, payload) => {
      state.loadingCategorySubType = true;
    },
    //
    getCategorySubTypeByIdRequest: (state, payload) => {
      state.edit = true;
    },
    editCategorySubTypeSuccess: (state, { payload }) => {
      state.categorySubType = payload;
    },
    editCategorySubTypeFail: (state) => {
      state.edit = false;
    },
    //update
    updateCategorySubTypeRequest: (state, payload) => {
      state.loadingUpdateCategorySubType = true;
    },
    updateCategorySubTypeSuccess: (state) => {
      state.loadingUpdateCategorySubType = false;
    },
    updateCategorySubTypeFail: (state) => {
      state.loadingUpdateCategorySubType = false;
    },
    //
    clearCategorySubTypeData: (state) => {
      state.categorySubType = null;
      state.edit = false;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingCategorySubType,
  getCategorySubTypeSuccess,
  getCategorySubTypeFail,
  getCategorySubTypeRequest,
  //
  getCategorySubTypeNextRequest,
  getCategorySubTypePreviousRequest,
  //
  getCategorySubTypeByIdRequest,
  editCategorySubTypeSuccess,
  editCategorySubTypeFail,
  //
  updateCategorySubTypeRequest,
  updateCategorySubTypeSuccess,
  updateCategorySubTypeFail,
  //
  clearCategorySubTypeData,
} = CategorySubType.actions;
export default CategorySubType.reducer;
