import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const userURL = "api/v1/user-app";

//get user data api
export const getUser = (data: getApiProps) => {
  const { rowsPerPage, page, search } = data;
  return axiosInstance.get(
    `${userURL}/users?offset=${rowsPerPage * page}&limit=${rowsPerPage}&search=${
      search ?? ""
    }${data?.filterData ? filterApiData(data?.filterData) : ""}`
  );
};
export const getUserGroup = (rowsPerPage: number) =>
  axiosInstance.get(`${userURL}/group-list?offset=0&limit=${rowsPerPage}`);

//handle search
export const searchUser = ({ rowsPerPage, search }: searchProps) => {
  return `${userURL}/users?offset=0&limit=${rowsPerPage}&search=${search ?? ""}`;
};
//create user
export const createUser = (body: string | FormData) =>
  axiosInstance.post(`${userURL}/register`, body);
//update user
export const updateUser = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${userURL}/update-profile/${id}`, body);
//get login user
export const LoginUser = (id: number | undefined) =>
  axiosInstance.get(`${userURL}/users/${id}`);
//
export const getUserById = (id: number) => {
  return axiosInstance.get(`${userURL}/users/${id}`);
};
