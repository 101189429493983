import {
  loadingOrganization,
  getOrganizationSuccess,
  getOrganizationFail,
  createOrganizationSuccess,
  createOrganizationFail,
  organizationEditSuccess,
  updateOrganizationSuccess,
  updateOrganizationFail,
  clearOrganizationData,
  getOrganizationRequest,
  updateOrganizationRequest,
  getOrganizationNextRequest,
  getOrganizationPreviousRequest,
  createOrganizationRequest,
  getOrganizationByIdRequest,
  organizationEditFail,
} from "./organizationSlice";
import { mergeMap } from "rxjs";
import {
  createOrganization,
  getOrganization,
  updateOrganization,
  getOrganizationById,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
  getGlobalOrganizationRequest,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();
// import { searchData } from "../../../CommonAppRedux/commonAppFunctions";
//get user epic
const getOrganizationEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOrganizationRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingOrganization());
      try {
        const response = await getOrganization(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getOrganizationSuccess(action?.payload)
        : getOrganizationFail()
    )
  );

//get organization by Id epic
const getOrganizationByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOrganizationByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getOrganizationById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? organizationEditSuccess(action.payload)
        : organizationEditFail()
    )
  );

//get next
const getOrganizationNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOrganizationNextRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingOrganization());
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getOrganizationSuccess(action?.payload)
        : getOrganizationFail()
    )
  );
//get previous
const getOrganizationPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getOrganizationPreviousRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingOrganization());
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getOrganizationSuccess(action?.payload)
        : getOrganizationFail()
    )
  );
//create user epic
const createOrganizationEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createOrganizationRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }
        const response = await createOrganization(body);
        if (response) {
          dispatch(getOrganizationRequest({ rowsPerPage, page }));
          dispatch(getGlobalOrganizationRequest());
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createOrganizationSuccess()
        : createOrganizationFail();
    })
  );
//update user epic
const updateOrganizationEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateOrganizationRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page } }) => {
      const {
        name,
        address,
        phone_no,
        alt_phone_no,
        mobile_no,
        alt_mobile_no,
        pan_no,
        email,
        website_url,
        facebook_url,
        linkedin_url,
        youtube_url,
        twitter_url,
        insta_url,
        whatsapp_url,
        viber_url,
        logo,
        favicon,
        remarks,
      } = values;
      try {
        let body = new FormData();
        // body.append("name", values?.name);
        // body.append("nameDisplay", values?.nameDisplay);
        // body.append("namePharmacy", values?.namePharmacy);
        // body.append("email", values?.email);
        // body.append("phoneNo1", values?.phoneNo1);
        // body.append("phoneNo2", values?.phoneNo2);
        // body.append("ownerName", values?.ownerName);
        // body.append("mobileNo", values?.mobileNo);
        // body.append("panNo", values?.panNo);
        // body.append("websiteUrl", values?.websiteUrl);
        // body.append("country", values?.country);
        // body.append("address", values?.address);
        // if (values?.favicon) {
        //   body.append("favicon", values?.favicon);
        // }
        // if (values?.remarks) {
        //   body.append("remarks", values?.remarks);
        // }
        // if (values?.logo) {
        //   body.append("logo", values?.logo);
        // }
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }
        const response = await updateOrganization(body, id);
        if (response) {
          dispatch(getOrganizationRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(getGlobalOrganizationRequest());
          dispatch(clearOrganizationData());
          dispatch(closeModal());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateOrganizationSuccess() : updateOrganizationFail()
    )
  );

//

export const organizationEpics = combineEpics(
  getOrganizationEpic,
  createOrganizationEpic,
  updateOrganizationEpic,
  getOrganizationNext,
  getOrganizationPrevious,
  getOrganizationByIdEpic
);
