// @ts-ignore
import adbs from "ad-bs-converter";
import moment from "moment";
import { filterData } from "../../Component/FilterData/types";
import { rowProps } from "../../Pages/ViewDetails/CommonTable/types";
import { enumTypes } from "../EnumValues/types";
import { FormikProps } from "formik";
import { Dispatch } from "react";
import { AnyAction } from "@reduxjs/toolkit";
import { commonAppFormikData } from "../../Pages/CommonAppRedux/CommonAppSlice";
import { globalSearchPostPerPage } from "./globalConstant";
//date formater
export const dateFormater = (date: string | Date | null): any => {
  // @ts-ignore
  if (!isNaN(Date.parse(date))) {
    if (date) {
      return Intl.DateTimeFormat("fr-CA", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }).format(typeof date === "string" ? new Date(date) : date);
    }
  }
};
//time formater
export const timeFormater = (date: Date | null | string) => {
  if (date) {
    return Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    }).format(typeof date === "string" ? new Date(date) : date);
  }
};
//get Today Date Bs
export const getTodayDateBs = () => {
  const date = new Date().toISOString().slice(0, 10);
  const adBs = adbs.ad2bs(date.split("-").join("/"));
  return `${adBs.en.year}-${
    String(adBs.en.month).length === 2 ? adBs.en.month : "0" + adBs.en.month
  }-${String(adBs.en.day).length === 2 ? adBs.en.day : "0" + adBs.en.day}`;
};
//get today date ad
export const getTodayDateAd = () => {
  const date = new Date().toISOString().slice(0, 10);
  return date;
};
// for getting current date
const current = new Date();
export const todayDate = `${current.getFullYear()}-${
  current.getMonth() + 1
}-${current.getDate()}`;
//function to convert ad-to-bs
export const convertDateAdToBs = (value: Date | null | string) => {
  var date = dateFormater(value);
  if (typeof date === "number" || typeof date === "string") {
    const adBs = adbs.ad2bs(dateFormater(value!).split("-").join("/"));
    let convertedDate = `${adBs.en.year}-${
      String(adBs.en.month).length === 2 ? adBs.en.month : "0" + adBs.en.month
    }-${String(adBs.en.day).length === 2 ? adBs.en.day : "0" + adBs.en.day}`;
    return convertedDate;
  } else {
    let value = new Date().toDateString();
    const adBs = adbs.ad2bs(dateFormater(value!).split("-").join("/"));
    let convertedDate = `${adBs.en.year}-${
      String(adBs.en.month).length === 2 ? adBs.en.month : "0" + adBs.en.month
    }-${String(adBs.en.day).length === 2 ? adBs.en.day : "0" + adBs.en.day}`;
    return convertedDate;
  }
};
//get age by year/month/day
export const getAge = (value: string, withoutAffix?: boolean) => {
  let years = moment().diff(value, "years");
  let months = moment().diff(value, "month");
  let days = moment().diff(value, "days");
  return months === 0
    ? withoutAffix
      ? days
      : `${days}Days`
    : years === 0
    ? withoutAffix
      ? months
      : `${months}mth`
    : withoutAffix
    ? years
    : `${years}Yrs`;
};

//get age type by dob
export const getAgeTypeByDob = (value: string) => {
  let years = moment().diff(value, "years");
  let months = moment().diff(value, "month");
  let days = moment().diff(value, "days");
  // let ageType = {}
  if (years !== 0) {
    return { id: 1, name: "Year" };
  } else if (months !== 0) {
    return { id: 2, name: "Months" };
  } else if (days !== 0) {
    return { id: 3, name: "Days" };
  }
};

//function to convert bs-to-ad
export const convertDateBsToAd = (value: string) => {
  const bsAd = adbs.bs2ad(value?.split("-").join("/"));
  let convertedDate = `${bsAd.year}-${bsAd.month}-${bsAd.day}`;
  return convertedDate;
};
//function to filter api data
export const filterApiData = (filterData: filterData) => {
  const { fromDateAd, toDateAd, fromDateBs, toDateBs, filterField } =
    filterData;
  // return array of string
  let filterFieldValue = filterField?.map(
    (x) =>
      `&${x.filterField ? x.filterField : x.id}=${x.filterFieldValue ?? ""}`
  );
  return `&date_after=${
    fromDateAd
      ? dateFormater(new Date(fromDateAd))
      : fromDateBs
      ? fromDateBs
      : ""
  }&date_before=${
    toDateAd ? dateFormater(new Date(toDateAd)) : toDateBs ? toDateBs : ""
  }${filterFieldValue?.join("")}`;
};
//function to check duplicate value
export const checkDuplicateValue = (
  duplicateDataList: any,
  filedName: string
) => {
  return duplicateDataList[0][`${filedName}`]?.length > 0
    ? duplicateDataList[0][`${filedName}`]?.length === 0
    : duplicateDataList[0][`${filedName}`] !== "undefined";
};
//custom round function
export const customRound = (num: number | string) => {
  // The rounding problem can be avoided by using numbers represented in exponential notation:
  // @ts-ignore
  return +(Math.round(num + "e+2") + "e-2");
};

//check if cash type already exixts
export const isChecked = (cashPaymentMode: rowProps[]) => {
  return cashPaymentMode?.some((payment) => payment.cash);
};
//getNetAmount
export const getNetAmount = (
  cost: string | number,
  qty: string | number,
  discountRate: string | number,
  taxRate: string | number
) => {
  return customRound(
    +cost * +qty -
      (+cost * +qty * +discountRate) / 100 +
      Number((+cost * +qty - (+cost * +qty * +discountRate) / 100) * +taxRate) /
        100
  );
};
//filter by id
export const filterById = (data: rowProps[], id: number, fieldName: string) =>
  data?.filter((data) => data[fieldName] === id) as any;
// get array of specified filed
export const getArrayOfSpecifiedField = (data: any, specifiedKey: string) =>
  data?.map((dataObj: any) => dataObj[specifiedKey]);
// convert array to string
export const splitArrayToStringById = (data: number[]) => data?.join(",");

export const getDobByAge = (
  age: string,
  ageType: enumTypes | null,
  formik: FormikProps<any>
) => {
  let dob;
  if (ageType === null || ageType.name === "Year") {
    dob = moment().subtract(age, "years");
  } else if (ageType?.name === "Months") {
    dob = moment().subtract(age, "months");
  } else {
    dob = moment().subtract(age, "days");
  }
  var dobAD = moment(dob).format("YYYY-MM-DD");
  formik.setFieldValue("dobDateAd", dobAD);
  const adBs = adbs.ad2bs(dobAD.split("-").join("/"));
  formik.setFieldValue(
    "dobDateBs",
    `${adBs.en.year}-${
      String(adBs.en.month).length === 2 ? adBs.en.month : "0" + adBs.en.month
    }-${String(adBs.en.day).length === 2 ? adBs.en.day : "0" + adBs.en.day}`
  );
};

//URL to file
export function dataURLtoFile(dataurl: any, filename: any) {
  var arr = dataurl?.split(","),
    mime = arr[0]?.match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
} // check equality between two objects
export const checkEqualityData = (
  values: any,
  initialValues: any,
  checkEquality: React.MutableRefObject<boolean>,
  dispatch: Dispatch<AnyAction>
) => {
  checkEquality.current =
    JSON.stringify(values) === JSON.stringify(initialValues) ? true : false;
  dispatch(commonAppFormikData(checkEquality.current));
};
// handle url when user search from autocomplete
export const getSearchURL = (value: string) =>
  `offset=0&limit=${globalSearchPostPerPage}&search=${value.trimStart()}`;

export const getReadTime = (content: string): number => {
  //creating a temp element and setting the html content to get the corresponding string representation
  const tempElem = document.createElement("div");
  tempElem.innerHTML = content;
  //for counting no of images in the content
  const imageCount = tempElem.getElementsByTagName("img").length;

  const textContent = tempElem.textContent;
  const wordCount = textContent?.trim().split(/\s+/).length;

  let readTimeInMinHigh: number = 0;
  if (wordCount) {
    readTimeInMinHigh = Math.ceil(wordCount / 265);
  }

  //for the first 10 image set the read time starting from 12 sec decrease it for subsiquent images and for images after 10th count set read time to 3 sec.

  let imageReadTime: number = 0;
  for (let i = 0; i < imageCount; i++) {
    if (i < 10) {
      imageReadTime += 12 - i;
    } else {
      imageReadTime += 3;
    }
  }
  const imageReadTimeInMinHigh = Math.ceil(imageReadTime / 60);
  return readTimeInMinHigh + imageReadTimeInMinHigh;
};


export const getTimeago = (dateStr: string) => {
  let timeAgo = "";
  const currentTime = new Date().getTime() / 1000;
  const published_date = new Date(dateStr).getTime() / 1000;
  const diffInSeconds = currentTime - published_date;
  if (diffInSeconds < 60) {
      timeAgo = "now";
  } else if (diffInSeconds < 3600) {
      timeAgo = `${Math.floor(diffInSeconds / 60)} minutes ago`;
  } else if (diffInSeconds < 86400) {
      timeAgo = `${Math.floor(diffInSeconds / 3600)} hours ago`;
  } else if (diffInSeconds > 86400 && diffInSeconds <= 172800) {
      timeAgo = `yesterday`;
  } else if (diffInSeconds > 172800 && diffInSeconds < 604800) {
      timeAgo = `${Math.floor(diffInSeconds / 86400)} days ago`;
  } else if (diffInSeconds > 604800 && diffInSeconds < 1209600) {
      timeAgo = `last week`;
  } else if (diffInSeconds > 1209600 && diffInSeconds < 2419200) {
      timeAgo = `${Math.floor(diffInSeconds / 604800)} weeks ago`;
  } else if (diffInSeconds > 2419200 && diffInSeconds < 4838400) {
      timeAgo = `last month`;
  } else if (diffInSeconds > 4838400 && diffInSeconds < 29030400) {
      timeAgo = `${Math.floor(diffInSeconds / 2419200)} months ago`;
  } else {
      const years = Math.floor(diffInSeconds / 29030400);
      timeAgo = `${years} ${years === 1 ? 'year' : 'years'} ago`;
  }

  return timeAgo
};


export const sortByProperty = (array: any, propertyName: string, ascending = true) => {
	array?.sort((a: any, b:any) => {
		if (a[propertyName] < b[propertyName]) {
			return ascending ? -1 : 1;
		}
		if (a[propertyName] > b[propertyName]) {
			return ascending ? 1 : -1;
		}
		return 0;
	});
	return array;
};