import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const contactModURL = "api/v1/customer-app/contact-from";
//get Category data api
export const getContact = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${contactModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
//handle search
export const searchContact = ({ rowsPerPage, search }: searchProps) => {
  return `${contactModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
