import { Action } from '@reduxjs/toolkit';
import { Observable, filter, map, mergeMap } from 'rxjs';
import {
	dispatchAction,
	stateAction,
} from '../../../../AppUtils/Utils/globalTypes';
import { getPublicUser, viewPublicUserById } from './api';
import {
	getPublicUserListFail,
	getPublicUserListRequest,
	getPublicUserListSuccess,
	getPublicUserNextRequest,
	getPublicUserPreviousRequest,
	viewPublicUserByIdFail,
	viewPublicUserByIdRequest,
	viewPublicUserByIdSuccess,
} from './publicUserSlice';
import { combineEpics } from 'redux-observable';
import { getNext, getPrevious } from '../../../CommonAppRedux/api';

const getPublicUserListEpic = (
	action$: Observable<Action>,
	_: stateAction,
	{ dispatch }: dispatchAction
) =>
	action$.pipe(
		filter(getPublicUserListRequest.match),
		mergeMap(async ({ payload }) => {
			try {
				const response = await getPublicUser(payload);
				return { payload: response.data };
			} catch (error) {
				return { error };
			}
		}),
		map(({ payload }) =>
			payload
				? getPublicUserListSuccess(payload)
				: getPublicUserListFail()
		)
	);

//get next
const getPublicUserNextEpic = (
	action$: Observable<Action>,
	_: stateAction,
	{ dispatch }: dispatchAction
) =>
	action$.pipe(
		filter(getPublicUserNextRequest.match),
		mergeMap(async ({ payload }) => {
			try {
				const response = await getNext(payload);
				return { payload: response.data };
			} catch (e) {
				return { error: e };
			}
		}),
		map((action) =>
			action?.payload
				? getPublicUserListSuccess(action?.payload)
				: getPublicUserListFail()
		)
	);

//get previous
const getPublicUserPreviousEpic = (
	action$: Observable<Action>,
	_: stateAction,
	{ dispatch }: dispatchAction
) =>
	action$.pipe(
		filter(getPublicUserPreviousRequest.match),
		mergeMap(async ({ payload }) => {
			try {
				const response = await getPrevious(payload);
				return { payload: response.data };
			} catch (e) {
				return { error: e };
			}
		}),
		map((action) =>
			action?.payload
				? getPublicUserListSuccess(action?.payload)
				: getPublicUserListFail()
		)
	);

//view public user details by id
const viewPublicUserByIdEpic = (
	action$: Observable<Action>,
	_: stateAction,
	{ dispatch }: dispatchAction
) =>
	action$.pipe(
		filter(viewPublicUserByIdRequest.match),
		mergeMap(async (action) => {
			try {
				const response = await viewPublicUserById(action.payload);
				return { payload: response.data };
			} catch (e) {
				return { error: e };
			}
		}),
		map((action) =>
			action?.payload
				? viewPublicUserByIdSuccess(action?.payload)
				: viewPublicUserByIdFail()
		)
	);
export const publicUserEpics = combineEpics(
	//
	getPublicUserListEpic,
	viewPublicUserByIdEpic,
	// pagination
	getPublicUserNextEpic
);
