import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

const userRoleModURL = "api/v1/user-group-app";

export const getUserRole = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${userRoleModURL}/group?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};
export const searchUserRole = ({ rowsPerPage, search }: searchProps) => {
  return `${userRoleModURL}/group?offset=0&limit=${rowsPerPage}&search=${search}`;
};
export const createUserRole = (body: string | FormData) =>
  axiosInstance.post(`${userRoleModURL}/group`, body);

export const updateUserRole = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${userRoleModURL}/group/${id}`, body);

//get data
export const getUserGroup = (postsPerPage: number) =>
  axiosInstance.get(`${userRoleModURL}?offset=0&limit=${postsPerPage}`);

//creating function
export const createUserGroup = (body: string | FormData) =>
  axiosInstance.post(`${userRoleModURL}`, body);

//deleting function
// export const deleteUserGroup = (id: number) =>
//   axiosInstance.delete(`${userRoleModURL}/${id}`);

//update function
export const updateUserGroup = (id: number, body: string | FormData) =>
  axiosInstance.patch(`${userRoleModURL}/${id}`, body);

// get usergroup permission to show in unselected permission list
export const getUserPermission = () => {
  return axiosInstance.get(`${userRoleModURL}/permission?limit=0&offset=0`);
};

//get usergroup permission category to show in dropdown
export const getUserGroupPermissionCategory = () => {
  return axiosInstance.get(
    `${userRoleModURL}/permission-category?offset=0&limit=0`
  );
};

//get user permission by permission category id
export const getUserPermissionByCategory = (id: number) => {
  return axiosInstance.get(
    `${userRoleModURL}/permission?category=${id}&limit=0`
  );
};

//get user role by id
export const getUserRoleById = (id: number) => {
  return axiosInstance.get(`${userRoleModURL}/group/${id}`);
};
