import { createSlice, createAction } from "@reduxjs/toolkit";
const initialState: any = {
  groups: [],
  edit: false,
  group: null,
  userPermissions: [],
  loadingUserPermission: false,
  userPermissionCategories: [],
  loadingUserPermissionCategory: false,
  loadingUserRole: false,
  loadingCreateUserRole: false,
  count: 0,
  next: "",
  previous: "",
};
export const getUserRoleRequest = createAction(
  "getUserRoleRequest",
  function prepare({ rowsPerPage, page, filterData }) {
    return {
      payload: {
        rowsPerPage,
        page,
        filterData,
      },
    };
  }
);
export const createUserRoleRequest = createAction(
  "createUserRoleRequest",
  function prepare({ values, rowsPerPage, page }) {
    return {
      payload: {
        values,
        rowsPerPage,
        page,
      },
    };
  }
);
export const updateUserRoleRequest = createAction(
  "updateUserRoleRequest",
  function prepare({ values, id, rowsPerPage, page }) {
    return {
      payload: {
        values,
        id,
        rowsPerPage,
        page,
      },
    };
  }
);
export const getAllUserRolePermissionsRequest = createAction(
  "getAllUserRolePermissionsRequest",
  function prepare(id) {
    return {
      payload: id,
    };
  }
);
export const getSpecificUserPermissionCategoryRequest = createAction(
  "getSpecificUserPermissionCategoryRequest",
  function prepare(id) {
    return {
      payload: id,
    };
  }
);
export const getSpecificUserPermissionsRequest = createAction(
  "getSpecificUserPermissionsRequest",
  function prepare(id) {
    return {
      payload: id,
    };
  }
);
export const getUserRoleNextRequest = createAction(
  "getUserRoleNextRequest",
  function prepare(next) {
    return {
      payload: next,
    };
  }
);
//get previous request
export const getUserRolePreviousRequest = createAction(
  "getUserRolePreviousRequest",
  function prepare(previous) {
    return {
      payload: previous,
    };
  }
);

export const UserRole = createSlice({
  name: "userRoleSlice",
  initialState,
  reducers: {
    loadingUserRole: (state) => {
      state.loadingUserRole = true;
    },
    getUserRoleSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingUserRole = false;
      state.groups = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getUserRoleFail: (state) => {
      state.loadingUserRole = false;
    },
    //get user permission by category
    getUserPermissionByCategoryRequest: (state, payload) => {
      state.loadingUserPermission = true;
    },
    // get all user permission (new)
    getUserPermissionRequest: (state) => {
      state.loadingUserPermission = true;
    },
    getUserPermissionSuccess: (state, { payload: { results } }) => {
      state.loadingUserPermission = false;
      state.userPermissions = results;
    },
    getUserPermissionFail: (state) => {
      state.loadingUserPermission = false;
    },
    // get user permission category (new)
    getUserPermissionCategoryRequest: (state) => {
      state.loadingUserPermissionCategory = true;
    },
    getUserPermissionCategorySuccess: (state, { payload: { results } }) => {
      state.loadingUserPermissionCategory = false;
      state.userPermissionCategories = results;
    },
    getUserPermissionCategoryFail: (state) => {
      state.loadingUserPermissionCategory = false;
    },
    // createUserRoleRequest: (state, payload) => {
    //   state.loadingCreateUserRole = true;
    //   state.closeModal = true;
    // },
    createUserRoleSuccess: (state) => {
      state.loadingCreateUserRole = false;
    },
    createUserRoleFail: (state) => {
      state.loadingCreateUserRole = false;
    },
    //
    getUserRoleByIdRequest: (state, payload) => {
      state.edit = true;
    },
    userRoleEditSuccess: (state, { payload }) => {
      state.group = payload;
    },
    userRoleEditFail: (state) => {
      state.edit = false;
    },
    //
    updateUserRoleSuccess: (state) => {
      state.loadingCreateUserRole = false;
    },
    updateUserRoleFail: (state) => {
      state.loadingCreateUserRole = false;
    },
    //get system module
    getUserGroupSystemModleRequest: (state) => {
      state.loadingUserGroupSystemModuleRequest = true;
      // state.closeModal = true;
    },
    getUserGroupSystemModuleSuccess: (state, { payload: { results } }) => {
      state.loadingUserGroupSystemModule = true;
      state.userGroupSystemModules = results;
    },
    getUserGroupSystemModuleFail: (state) => {
      state.loadingUserGroupSystemModule = false;
    },
    clearUserRoleData: (state) => {
      state.edit = false;
      state.group = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingUserRole,
  getUserRoleSuccess,
  getUserRoleFail,
  // createUserRoleRequest,
  getUserGroupSystemModleRequest,
  createUserRoleSuccess,
  createUserRoleFail,
  updateUserRoleSuccess,
  updateUserRoleFail,
  clearUserRoleData,
  //
  getUserRoleByIdRequest,
  userRoleEditSuccess,
  userRoleEditFail,
  //
  getUserPermissionCategoryRequest,
  getUserPermissionCategorySuccess,
  getUserPermissionCategoryFail,
  //
  getUserPermissionRequest,
  getUserPermissionSuccess,
  getUserPermissionFail,
  //
  getUserPermissionByCategoryRequest,
  //
  getUserGroupSystemModuleSuccess,
  getUserGroupSystemModuleFail,
} = UserRole.actions;
export default UserRole.reducer;
