import { Box, Divider, List, ListItem, MenuItem, Stack } from '@mui/material';
import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import { Badge, IconButton, Menu, Typography } from '@mui/material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { AccountCircle } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
// import ItemListDetail from "../Component/ViewDetails/ItemListDetail";
import ShortcutIcon from '@mui/icons-material/Shortcut';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import KeyIcon from '@mui/icons-material/Key';
//
import { headerStyles } from './HeaderStyles';
import { loginFail } from '../../../Pages/Login/Redux/authSlice';
import TooltipContent from '../../Tooltip/TooltipContent';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import './header.css';
import NotificationModal from '../../Notification';
import { useAppSelector } from '../../../AppUtils/Utils/appHooks';
import { notificationSelector } from '../../Notification/Redux/selector';
// import ShortCutKey from "../Component/ViewDetails/ShortCutKey";
// const Modal = lazy(() => import("../Component/Modal/Modal"));
const FixedAppBar = ({ openSidebar, setOpenSidebar }: any) => {
	const dispatch = useDispatch();
	const classes = headerStyles();
	const {unread_notification} = useAppSelector(notificationSelector)
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [notificationAnchorEl, setNotificationAnchorEl] =
		useState<null | HTMLElement>(null);
	const [showModalShortcut, setShowModalShortcut] = useState(false);
	// state to handle fullscreen
	const [exit, setExit] = useState(false);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event?.currentTarget);
	};
	const handleOpenNotificationModal = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		setNotificationAnchorEl(event?.currentTarget);
	};
	const { openMasterSidebar } = openSidebar;
	const handleClose = () => {
		setAnchorEl(null);
		setNotificationAnchorEl(null);
	};
	const handleProfile = () => {
		setAnchorEl(null);
	};
	const handleShortcut = () => {
		setShowModalShortcut(true);
	};
	//handle Full screen
	const handleFullScreen = () => {
		setExit((prev) => !prev);
		if (document.fullscreenElement) {
			document.exitFullscreen();
		} else {
			document.documentElement.requestFullscreen();
		}
	};
	const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			backgroundColor: '#f5f5f9',
			color: 'rgba(0, 0, 0, 0.87)',
			maxWidth: 220,
			fontSize: theme.typography.pxToRem(12),
			border: '1px solid #dadde9',
		},
	}));
	const [active, setActive] = useState('master');
	const [showModal, setShowModal] = useState(false);
	//set the active field
	useEffect(() => {
		if (openMasterSidebar) {
			setActive('master');
		}
	}, [openMasterSidebar]);
	const [keyPressed, setKeyPressed] = useState(false);

	useEffect(() => {
		const handleKeyPress = (event: any) => {
			if (event.ctrlKey && event.code === 'KeyI') {
				setKeyPressed(true);
				setShowModal(true);
			}
		};
		//add event if key press is false
		!keyPressed &&
			window.addEventListener('keypress', handleKeyPress, {
				passive: true,
			});
		return () => {
			window.removeEventListener('keypress', handleKeyPress);
		};
	}, [keyPressed]);
	useEffect(() => {
		if (showModal === false) {
			setKeyPressed(false);
			setActive('master');
		}
	}, [showModal]);
	const handleLogout = () => {
		setAnchorEl(null);
		setNotificationAnchorEl(null);
		dispatch(loginFail());
	};
	return (
		<>
			<AppBar position="fixed" className={classes.fixedHeader}>
				<Grid
					container
					spacing={2}
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
						position: 'relative',
					}}>
					<Grid item>
						{/* <Toolbar disableGutters>
              <Grid justifyContent="center">
                <Link to="/core-setup">
                  <button
                    className={`fixed-header-button ${
                      active === "master" ? "activeButton" : ""
                    }`}
                    onClick={() => {
                      setActive("master");
                      localStorage?.setItem("activeSidebar", "master");
                      // setOpenLabSideBar(false);
                      // setOpenPharmacySidebar(false);
                      // setOpenMasterSidebar(true);
                      // setOpenInvSidebar(false);
                      // setOpenSidebar({
                      //   openMasterSidebar: true,
                      // });
                    }}
                  >
                    <span>Core Setup</span>
                  </button>
                </Link>
              </Grid>
            </Toolbar> */}
					</Grid>
					<Grid item className="fixedHeaderIcons">
						<Toolbar disableGutters>
							<Grid
								container
								spacing={1}
								direction="row"
								justifyContent="center"
								alignItems="center">
								{/* <Grid item>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography color="inherit">
                          ShortCut Keys For System
                        </Typography>
                        <em>{"We Added All Required "}</em>
                        <em>{"It's very Convenience For You"}</em>{" "}
                      </React.Fragment>
                    }
                  >
                    <IconButton
                      color="inherit"
                      className={classes.badgeIcons}
                      onClick={handleShortcut}
                    >
                      <Badge badgeContent={0} color="secondary">
                        <ShortcutIcon
                          className={classes.fixedHeaderIconsButton}
                          fontSize="large"
                        />
                      </Badge>
                    </IconButton>
                  </HtmlTooltip>
                </Grid> */}
								<Grid item>
									<IconButton
										color="inherit"
										className={classes.badgeIcons}
										onClick={handleFullScreen}>
										<Badge
											badgeContent={0}
											color="secondary">
											<TooltipContent
												title={
													exit
														? 'Exit Full Screen'
														: 'Full Screen'
												}>
												{exit ? (
													<FullscreenExitIcon
														className={
															classes.fixedHeaderIconsButton
														}
													/>
												) : (
													<FullscreenIcon
														className={
															classes.fixedHeaderIconsButton
														}
													/>
												)}
											</TooltipContent>
										</Badge>
									</IconButton>
								</Grid>
								<Grid item>
									<IconButton
										color="inherit"
										aria-controls="notification-menu"
										aria-haspopup="true"
										onClick={handleOpenNotificationModal}
										className={classes.badgeIcons}>
										<Badge
											badgeContent={unread_notification}
											color="secondary">
											<NotificationsIcon
												className={
													classes.fixedHeaderIconsButton
												}
												fontSize="large"
											/>
										</Badge>
									</IconButton>
								</Grid>
								<Menu
									id="notification-menu"
									anchorEl={notificationAnchorEl}
									keepMounted
									open={Boolean(notificationAnchorEl)}
									onClose={handleClose}>
									
									<NotificationModal />
								</Menu>

								<Grid item>
									<IconButton
										color="inherit"
										aria-controls="simple-menu"
										aria-haspopup="true"
										onClick={handleClick}
										className={classes.badgeIcons}>
										<AccountCircle
											className={
												classes.fixedHeaderIconsButton
											}
											fontSize="large"
										/>
									</IconButton>
								</Grid>
								<Menu
									id="simple-menu"
									anchorEl={anchorEl}
									keepMounted
									open={Boolean(anchorEl)}
									onClose={handleClose}>
									<Link to="/" className={classes.menuLink}>
										<MenuItem onClick={handleProfile}>
											<PersonIcon color="primary" />
											&nbsp; Profile
										</MenuItem>
									</Link>
									{/* {is_superuser ||
                  handlePermission(
                    permissions,
                    passPermission?.change_user_password
                  ) ? ( */}
									<Link
										to="/change-password"
										className={classes.menuLink}>
										<MenuItem onClick={handleClose}>
											<KeyIcon color="primary" />
											&nbsp; Change Password
										</MenuItem>
									</Link>
									{/* // ) : null} */}

									<Link to="/" className={classes.menuLink}>
										<MenuItem onClick={handleLogout}>
											<PowerSettingsNewIcon color="primary" />
											&nbsp; Logout
										</MenuItem>
									</Link>
								</Menu>
							</Grid>
						</Toolbar>
					</Grid>
				</Grid>
			</AppBar>
			<>
				{/* {showModal && (
          <Suspense fallback={<></>}>
            <Modal
              showModal={showModal}
              setShowModal={setShowModal}
              types="itemList"
              title="Item List Details"
              maxWidth="lg"
              edit={false}
            >
              <ItemListDetail
                setShowModal={setShowModal}
                showModal={showModal}
                types="itemList"
                active
              />
            </Modal>
          </Suspense>
        )} */}
				{/* {showModalShortcut && (
          <Suspense fallback={<></>}>
            <Modal
              showModal={showModalShortcut}
              setShowModal={setShowModalShortcut}
              types="shortCutKey"
              title="Shortcut Key List"
              maxWidth="lg"
              edit={false}
            >
              <ShortCutKey />
            </Modal>
          </Suspense>
        )} */}
			</>
		</>
	);
};
export default FixedAppBar;
