import React, { FC } from "react";
import { Route } from "react-router";
import { ProtectedRouteProps } from "./types";
const ProtectedRoute: FC<ProtectedRouteProps> = ({
  component: Component,
  permission,
  path,
  ...rest
}) => {
  //   const permissions = useAppSelector((state) => state.auth.permissions);
  //   const is_superusers= useAppSelector((state) => state.auth.is_superuser);
  //provided value to the child component
  //   const providerValue = useMemo(
  //     () => ({
  //       permissions,
  //       is_superuser,
  //     }),
  //     [permissions, is_superuser]
  //   );
  return (
    <>
      <Route
        {...rest}
        render={(props) => {
          return <Component {...props} />;
        }}
      />
    </>
  );
};

export default React.memo(ProtectedRoute);
