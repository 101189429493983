import {
  getCompanySuccess,
  getCompanyFail,
  loadingCompany,
  getCompanyRequest,
  getCompanyNextRequest,
  getCompanyPreviousRequest,
  updateCompanyRequest,
  clearCompanyData,
  updateCompanySuccess,
  updateCompanyFail,
  getCompanyDetailsRequest,
  getCompanyDetailsSuccess,
  getCompanyDetailsFail,
  updateStatusSuccess,
  updateStatusFail,
  updateStatusRequest,
  getCompanyByIdRequest,
  editCompanySuccess,
  editCompanyFail,
  //
  getCompanyCategoryRequest,
  getCompanyCategorySuccess,
  getCompanyCategoryFail,
  //
  getCompanyCategorySubTypeRequest,
  getCompanyCategorySubTypeSuccess,
  getCompanyCategorySubTypeFail,
  //
  getCompanySubCategoryRequest,
  getCompanySubCategorySuccess,
  getCompanySubCategoryFail,
  //
  getCompanySectorRequest,
  getCompanySectorSuccess,
  getCompanySectorFail,
} from "./companySlice";
import { mergeMap } from "rxjs";
import {
  getCompany,
  getCompanyById,
  getCompanyCategory,
  getCompanyCategorySubType,
  getCompanyDetails,
  getCompanySector,
  getCompanySubCategory,
  updateCompany,
  updateStatus,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get Company
const getCompanyEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCompanyRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingCompany());
      try {
        const response = await getCompany(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getCompanySuccess(action?.payload) : getCompanyFail()
    )
  );

//get next
const getCompanyNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCompanyNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getCompanySuccess(action?.payload) : getCompanyFail()
    )
  );

//get previous
const getCompanyPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCompanyPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getCompanySuccess(action?.payload) : getCompanyFail()
    )
  );
//update category epic
const updateCompanyEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateCompanyRequest.match),
    mergeMap(
      async ({
        payload: { values, id, rowsPerPage, page, search, categoriesValue },
      }) => {
        const {
          company_turnovers,
          company_documents,
          company_attribute,
          company_credentials,
          company_franchise_location,
          logo,
          image,
          ...restValues
        } = values;
        try {
          const body = new FormData();
          for (let [key, value] of Object.entries(restValues)) {
            // @ts-ignore
            body.append(`${key}`, value);
          }

          company_turnovers?.forEach((comp: any, i: any) => {
            body.append(`company_turnovers[${i}][year]`, comp?.year);
            body.append(`company_turnovers[${i}][turnover]`, comp?.turnover);
          });

          company_franchise_location?.forEach((comp: any, i: any) => {
            body.append(
              `company_franchise_location[${i}][location]`,
              comp?.location
            );
          });

          company_documents?.forEach((doc: any, i: any) => {
            body.append(`company_documents[${i}][document]`, doc?.document);
            body.append(`company_documents[${i}][url]`, doc?.url);
            body.append(
              `company_documents[${i}][is_specific_document]`,
              doc?.is_specific_document
            );
          });

          if (typeof image !== "string") {
            body.append("image", image);
          }

          if (typeof logo !== "string") {
            body.append("logo", logo);
          }

          Object.keys(company_attribute).map((key) => {
            if (key === ("bid" || "ngt" || "fix")) {
              return;
            }
            body.append(`company_attribute[${key}]`, company_attribute[key]);
          });

          Object.keys(company_credentials)?.map((cred) => {
            body.append(
              `company_credentials[${cred}]`,
              company_credentials[cred]
            );
          });

          const response = await updateCompany(body, id);

          if (response) {
            dispatch(
              getCompanyRequest({
                rowsPerPage,
                page,
                search,
                id: categoriesValue?.id,
              })
            );
            dispatch(alertSuccessAction(messages.updateMessage));
            dispatch(closeModal());
            dispatch(clearCompanyData());
          }
          return { payload: { response, rowsPerPage } };
        } catch (e) {
          dispatch(alertErrorAction(messages.updateFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) =>
      action?.payload ? updateCompanySuccess() : updateCompanyFail()
    )
  );
//update status epic
const updateStatusEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateStatusRequest.match),
    mergeMap(
      async ({
        payload: {
          values,
          values: { id },
          rowsPerPage,
          page,
          search,
          setUpdateStatus,
          categoriesValue,
        },
      }) => {
        try {
          const body = JSON.stringify(values);
          const response = await updateStatus(body, id);
          if (response) {
            dispatch(
              getCompanyRequest({
                rowsPerPage,
                page,
                search,
                id: categoriesValue?.id,
              })
            );
            dispatch(alertSuccessAction(messages.updateMessage));
            setUpdateStatus(false);
            dispatch(clearCompanyData());
          }
          return { payload: { response, rowsPerPage } };
        } catch (e) {
          dispatch(alertErrorAction(messages.updateFailMessage));
          return { error: e };
        }
      }
    ),
    map((action) =>
      action?.payload ? updateStatusSuccess() : updateStatusFail()
    )
  );
//get compnay details epic
const getCompanyDetailsEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCompanyDetailsRequest.match),
    mergeMap(async ({ payload: { id } }) => {
      try {
        const response = await getCompanyDetails(id);
        return { payload: response.data };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCompanyDetailsSuccess(action?.payload)
        : getCompanyDetailsFail()
    )
  );

//get compnay details epic
const getCompanyByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCompanyByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getCompanyById(action?.payload);
        return { payload: response.data };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? editCompanySuccess(action?.payload) : editCompanyFail()
    )
  );

//get company category
const getCompanyCategoryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCompanyCategoryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getCompanyCategory();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCompanyCategorySuccess(action?.payload)
        : getCompanyCategoryFail()
    )
  );

//get company category subtype
const getCompanyCategorySubTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCompanyCategorySubTypeRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getCompanyCategorySubType();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCompanyCategorySubTypeSuccess(action?.payload)
        : getCompanyCategorySubTypeFail()
    )
  );

//get company sub category
const getCompanySubCategoryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCompanySubCategoryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getCompanySubCategory();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCompanySubCategorySuccess(action?.payload)
        : getCompanySubCategoryFail()
    )
  );

//get company sector
const getCompanySectorEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCompanySectorRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getCompanySector();
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getCompanySectorSuccess(action?.payload)
        : getCompanySectorFail()
    )
  );

export const companyEpics = combineEpics(
  getCompanyEpic,
  getCompanyNext,
  getCompanyPrevious,
  updateCompanyEpic,
  getCompanyDetailsEpic,
  updateStatusEpic,
  getCompanyByIdEpic,
  getCompanyCategoryEpic,
  getCompanyCategorySubTypeEpic,
  getCompanySubCategoryEpic,
  getCompanySectorEpic
);
