import { createSlice } from "@reduxjs/toolkit";
import { initialStateProfessional } from "./types";

const initialState: initialStateProfessional = {
  professionals: [],
  loadingProfessional: false,
  loadingCreateProfessional: false,
  edit: false,
  professional: null,
  count: 0,
  previous: "",
  next: "",
};

export const Professional = createSlice({
  name: "Professional Reducer",
  initialState,
  reducers: {
    getProfessionalRequest: (state, payload) => {
      state.loadingProfessional = true;
    },
    getProfessionalSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingProfessional = false;
      state.professionals = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getProfessionalFail: (state) => {
      state.loadingProfessional = false;
    },
    getProfessionalNextRequest: (state, payload) => {
      state.loadingProfessional = true;
    },
    getProfessionalPreviousRequest: (state, payload) => {
      state.loadingProfessional = true;
    },
    getProfessionalByIdRequest: (state, payload) => {
      state.edit = true;
    },
    createProfessionalRequest: (state, payload) => {
      state.loadingCreateProfessional = true;
    },
    updateProfessionalRequest: (state, payload) => {
      state.loadingCreateProfessional = true;
    },
    createProfessionalSuccess: (state) => {
      state.loadingCreateProfessional = false;
    },
    createProfessionalFail: (state) => {
      state.loadingCreateProfessional = false;
    },
    professionalEditSuccess: (state, { payload }) => {
      state.professional = payload;
    },
    professionalEditFail: (state) => {
      state.edit = false;
    },
    updateProfessionalSuccess: (state) => {
      state.loadingCreateProfessional = false;
    },
    updateProfessionalFail: (state) => {
      state.loadingCreateProfessional = false;
    },
    clearProfessionalData: (state) => {
      state.edit = false;
      state.professional= null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getProfessionalRequest,
  getProfessionalSuccess,
  getProfessionalFail,
  //
  getProfessionalPreviousRequest,
  getProfessionalNextRequest,
  //
  createProfessionalRequest,
  createProfessionalSuccess,
  createProfessionalFail,
  //
  professionalEditSuccess,
  updateProfessionalSuccess,
  updateProfessionalRequest,
  professionalEditFail,
  updateProfessionalFail,
  //
  clearProfessionalData,
  getProfessionalByIdRequest,
} = Professional.actions;
export default Professional.reducer;
