import { createAction, createSlice } from "@reduxjs/toolkit";
import { branch } from "./types";
import setCookie from "../../../AppUtils/Utils/Cookies/setCookie";
import deleteCookie from "../../../AppUtils/Utils/Cookies/deleteCookie";
export interface AuthState {
  isAuthenticated: boolean;
  loadingAuth: boolean;
  branches: branch[];
  userName: string;
  id: number | null;
  authError: boolean;
  is_superuser: boolean;
  photo: string | null;
  open: boolean;
  message: string;
  permissions: any;
}
const initialState: AuthState = {
  isAuthenticated: false,
  loadingAuth: false,
  branches: [],
  permissions: [],
  userName: "",
  id: null,
  authError: false,
  is_superuser: false,
  photo: null,
  open: false,
  message: "",
};

export const loginRequest = createAction(
  "loginRequest",
  function prepare(body) {
    return {
      payload: body,
    };
  }
);
export const getBranchRequest = createAction(
  "getBranchRequest",
  function prepare() {
    return {
      payload: {},
    };
  }
);

// export const getLoginRequestFail = createAction("loginRequestFail");
const Auth = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    loadingLogin: (state) => {
      state.loadingAuth = true;
    },
    loginSuccess: (state, action) => {
      setCookie("accessToken", action.payload.tokens.access, {
        //if https secure url header then set secure true
        secure: true,
        // httpOnly: true,
        "max-age": 360000,
        sameSite: "Lax",
      });
      setCookie("refreshToken", action.payload.tokens.refresh, {
        secure: true,
        // httpOnly: true,
        "max-age": 3600000,
        sameSite: "Lax",
      });
      setCookie("isAuthenticated", true, {
        secure: true,
        //  httpOnly: false,
        "max-age": 3600000,
        sameSite: "Lax",
      });
      state.loadingAuth = false;
      state.id = action?.payload?.id;
      state.isAuthenticated = true;
      state.userName = action.payload.userName;
      state.is_superuser = action?.payload?.is_superuser;
      state.authError = false;
      state.permissions = action?.payload?.permissions;
      state.photo = action.payload.photo;
    },
    loginFail: (state) => {
      deleteCookie("refreshToken");
      deleteCookie("accessToken");
      deleteCookie("isAuthenticated");
      state.loadingAuth = false;
      state.id = null;
      state.isAuthenticated = false;
      state.userName = "";
      state.is_superuser = false;
      state.authError = true;
      state.permissions = [];
      state.photo = null;
    },
    getBranchesSuccess: (state, { payload }) => {
      state.branches = payload;
    },
    getBranchesFail: (state) => {},
  },
});
export const {
  loginSuccess,
  loadingLogin,
  loginFail,
  getBranchesSuccess,
  getBranchesFail,
} = Auth.actions;
export default Auth.reducer;
