import { createSlice } from "@reduxjs/toolkit";
import { paymentDetails } from "../types";
const initialState: any = {
  paymentDetails: [],
  loadingPurchasePaymentMode: false,
  edit: false,
  paymentDetail: null,
};

// purchase slice
export const PaymentDetails = createSlice({
  name: "paymentDetailsReducer",
  initialState,
  reducers: {
    addPaymentDetails: (state, { payload }) => {
      state.paymentDetails = [...state.paymentDetails, payload];
      state.purchaseMasterData = payload;
      state.edit = state.edit && !state.edit;
    },
    deletePaymentDetails: (state, { payload }) => {
      state.paymentDetails = state.paymentDetails?.filter(
        (details: paymentDetails) => details?.uniqueId !== payload
      );
    },
    handleEditSuccess: (state, { payload }) => {
      state.edit = true;
      state.paymentDetail = payload;
      state.paymentDetails = state.paymentDetails?.filter(
        (details: paymentDetails) => details?.uniqueId !== payload?.uniqueId
      );
    },
    clearPartyPayment:(state)=>{
      state.paymentDetails=[];
    }
  },
});
// Action creators are generated for each case reducer function
export const { addPaymentDetails, deletePaymentDetails, handleEditSuccess,clearPartyPayment } =
  PaymentDetails.actions;
export default PaymentDetails.reducer;
