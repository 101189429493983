import { createAction, createSlice } from "@reduxjs/toolkit";
import {
  defaultPage,
  defaultRowsPerPage,
} from "../../AppUtils/Utils/globalConstant";
import { commonAppProps } from "./types";
const initialState: commonAppProps = {
  open: false,
  error: false,
  info: false,
  success: false,
  message: "",
  appType: "",
  rowsPerPage: defaultRowsPerPage,
  page: defaultPage,
  detailsRowsPerPage: defaultRowsPerPage,
  detailsPage: defaultPage,
  showModal: false,
  showReferenceModal: false,
  search: "",
  selectedModuleIndex: 0,
  formikData: false,
  hideAddButton: false,
  hideTabAndAction: false,
  hideSidebarInitially: false,
  hideCard: false,
  showItemListButton: false,
  showPurchaseModuleNote: false,
  passPermission: null,
  globalOrganizations: null,
  loadingGlobalOrganization: false,
};
export const getGlobalOrganizationRequest = createAction(
  "getGlobalOrganizationRequest",
  function prepare() {
    return {
      payload: "",
    };
  }
);
//update request action
export const updateRequest = createAction(
  "updateRequest",
  function prepare({ values, id, rowsPerPage, page }) {
    return {
      payload: {
        values,
        id,
        rowsPerPage,
        page,
      },
    };
  }
);
// get next request
export const getNextRequest = createAction(
  "getNextRequest",
  function prepare(next) {
    return {
      payload: next as string,
    };
  }
);
//get previous request
export const getPreviousRequest = createAction(
  "getPreviousRequest",
  function prepare(previous) {
    return {
      payload: previous as string,
    };
  }
);
//create request action
export const commonAppSlice = createSlice({
  name: "commonAppReducer",
  initialState,
  reducers: {
    alertSuccessAction: (state, { payload }) => {
      state.open = true;
      state.success = true;
      state.message = payload;
    },
    alertErrorAction: (state, { payload }) => {
      state.open = true;
      state.error = true;
      state.message = payload;
    },
    alertInfoAction: (state, { payload }) => {
      state.open = true;
      state.info = true;
      state.message = payload;
    },
    closeAlertAction: (state) => {
      state.open = false;
      state.info = false;
      state.success = false;
      state.error = false;
      state.message = "";
    },
    commonAppTypes: (
      state,
      {
        payload: {
          types,
          hideAddButton,
          hideTabAndAction,
          hideSidebarInitially,
          hideCard,
          showItemListButton,
          showPurchaseModuleNote,
          passPermission,
        },
      }
    ) => {
      state.passPermission = passPermission;
      state.appType = types;
      state.hideAddButton = hideAddButton;
      state.hideTabAndAction = hideTabAndAction;
      state.hideCard = hideCard;
      state.hideSidebarInitially = hideSidebarInitially;
      state.showItemListButton = showItemListButton;
      state.showPurchaseModuleNote = showPurchaseModuleNote;
    },
    commonAppFormikData: (state, { payload }) => {
      state.formikData = payload;
    },
    commonAppRowsPerPage: (state, { payload }) => {
      state.rowsPerPage = payload;
    },
    commonAppPage: (state, { payload }) => {
      state.page = payload;
      // state.appType = "";
    },
    clearSearchedData: (state) => {
      state.search = "";
      state.rowsPerPage = defaultRowsPerPage;
    },
    commonAppDetailsRowsPerPage: (state, { payload }) => {
      state.detailsRowsPerPage = payload;
    },
    commonAppDetailsPage: (state, { payload }) => {
      state.detailsPage = payload;
    },
    commonAppIndex: (state, { payload }) => {
      state.selectedModuleIndex = payload;
      // state.appType = "";
    },
    closeModal: (state) => {
      // state.appType.splice(-1, 1);
      state.showModal = false;
      // current(state.appType);
    },
    showAppModal: (state, { payload }) => {
      state.showModal = payload;
    },
    showReferenceRangeModal: (state) => {
      state.showReferenceModal = !state.showReferenceModal;
    },
    searchData: (state, { payload }) => {
      state.search = payload;
    },

    commonAppTypesClear: (state) => {
      state.appType = "";
    },
    //global organization
    getGlobalOrganizationRequest: (state) => {
      state.loadingGlobalOrganization = true;
    },
    getGlobalOrganizationSuccess: (state, { payload: { results } }) => {
      state.loadingGlobalOrganization = false;
      state.globalOrganizations = results[0] ?? null;
    },
    getGloablOrganizationFail: (state) => {
      state.loadingGlobalOrganization = false;
    },
    // clearCommonPagination: (state) => {
    //   state.page = defaultPage;
    // },
  },
});
// Action creators are generated for each case reducer function
export const {
  commonAppTypes,
  closeModal,
  commonAppRowsPerPage,
  commonAppPage,
  commonAppDetailsRowsPerPage,
  commonAppDetailsPage,
  showAppModal,
  showReferenceRangeModal,
  alertSuccessAction,
  alertErrorAction,
  alertInfoAction,
  closeAlertAction,
  searchData,
  commonAppIndex,
  commonAppTypesClear,
  commonAppFormikData,
  clearSearchedData,
  //
  // getGlobalOrganizationRequest,
  getGlobalOrganizationSuccess,
  getGloablOrganizationFail,
  // clearCommonPagination,
} = commonAppSlice.actions;
//export reducer
export default commonAppSlice.reducer;
