import { axiosInstance } from "../../../AppUtils/Utils/axios";

import { branch, credential, user } from "./types";

const loginModURL = "api/v1/user-app";

export const getLogin = (body: credential) =>
  axiosInstance.post<user>(`${loginModURL}/login`, body);
// export const getAllBranches = () => axiosInstance.get<branch[]>(`branches`);
//for logout
export const logout = (body: string) =>
  axiosInstance.post(`${loginModURL}/logout`, body);
