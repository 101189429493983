import {
  getContactSuccess,
  getContactFail,
  loadingContact,
  getContactRequest,
  getContactNextRequest,
  getContactPreviousRequest,
} from "./contactSlice";
import { mergeMap } from "rxjs";
import { getContact } from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";

export const controller = new AbortController();

// get Contact
const getContactEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getContactRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingContact());
      try {
        const response = await getContact(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getContactSuccess(action?.payload) : getContactFail()
    )
  );

//get next
const getContactNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getContactNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getContactSuccess(action?.payload) : getContactFail()
    )
  );

//get previous
const getContactPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getContactPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getContactSuccess(action?.payload) : getContactFail()
    )
  );

export const contactEpics = combineEpics(
  getContactEpic,
  getContactNext,
  getContactPrevious
);
