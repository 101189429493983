import { axiosInstance } from "../../AppUtils/Utils/axios";

//get next
export const getNext = (next: string) => axiosInstance.get(next);
//get previous
export const getPrevious = (next: string) => axiosInstance.get(next);

//global organization
export const getGlobalOrganization = () => {
  return axiosInstance.get("/api/v1/public/core/organization-setup");
};
