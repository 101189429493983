import { createSlice } from "@reduxjs/toolkit";
import { initialStateSlider } from "./types";

const initialState: initialStateSlider = {
  sliders: [],
  loadingSlider: false,
  loadingCreateSlider: false,
  edit: false,
  slider: null,
  count: 0,
  previous: "",
  next: "",
};

export const Slider = createSlice({
  name: "Slider Reducer",
  initialState,
  reducers: {
    getSliderRequest: (state, payload) => {
      state.loadingSlider = true;
    },
    getSliderSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingSlider = false;
      state.sliders = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getSliderFail: (state) => {
      state.loadingSlider = false;
    },
    getSliderNextRequest: (state, payload) => {
      state.loadingSlider = true;
    },
    getSliderPreviousRequest: (state, payload) => {
      state.loadingSlider = true;
    },
    getSliderByIdRequest: (state, payload) => {
      state.edit = true;
    },
    createSliderRequest: (state, payload) => {
      state.loadingCreateSlider = true;
    },
    updateSliderRequest: (state, payload) => {
      state.loadingCreateSlider = true;
    },
    createSliderSuccess: (state) => {
      state.loadingCreateSlider = false;
    },
    createSliderFail: (state) => {
      state.loadingCreateSlider = false;
    },
    sliderEditSuccess: (state, { payload }) => {
      state.slider = payload;
    },
    sliderEditFail: (state) => {
      state.edit = false;
    },
    updateSliderSuccess: (state) => {
      state.loadingCreateSlider = false;
    },
    updateSliderFail: (state) => {
      state.loadingCreateSlider = false;
    },
    clearSliderData: (state) => {
      state.edit = false;
      state.slider = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getSliderRequest,
  getSliderSuccess,
  getSliderFail,
  //
  getSliderPreviousRequest,
  getSliderNextRequest,
  //
  createSliderRequest,
  createSliderSuccess,
  createSliderFail,
  //
  sliderEditSuccess,
  updateSliderSuccess,
  updateSliderFail,
  updateSliderRequest,
  sliderEditFail,
  getSliderByIdRequest,
  //
  clearSliderData,
} = Slider.actions;
export default Slider.reducer;
