import { makeStyles } from "@mui/styles";
import {
  styled,
  useTheme,
  Theme,
  CSSObject,
  alpha,
} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import InputBase from "@mui/material/InputBase";

const drawerWidth = 230;

export const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },

  headerText: {
    minWidth: "9rem !important",
    height: "2rem !important",
    backgroundColor: "#305bb3 !important",
    color: "#fff !important",
    pointerEvents: "none",
    maxWidth: "100% !important",
    margin: "auto !important",
  },

  toolbarTop_layout: {
    marginTop: 50,
  },
  toolbarIcon: {
    color: "white",
    justifyContent: "flex-end",
    textAlign: "center",
    padding: "8px",
    alignItems: "center",
    fontWeight: "400",
  },
  container: {
    paddingTop: "32px",
    paddingBottom: "32px",
  },
  paper: {
    padding: "16px",
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  sidebarList: {
    color: "white",
  },
  sidebarChildrenList: {
    color: "white",
  },
  ListItemhover: {
    padding: "6px 12px !important",
    "&:hover": {
      backgroundColor: "#2f59b2",
      color: "#fff",
    },
  },
  subMenuPadding: {
    padding: "2px 40px !important",
    "&:hover": {
      backgroundColor: "#94c1d4",
      color: "#fff",
    },
  },
  Iconbg: {
    color: "#fff !important",
    minWidth: "26px !important",
  },
  search: {
    position: "relative",
    // borderRadius: theme.shape.borderRadius,
    backgroundColor: "rgb(255 255 255 / 33%)",
    "&:hover": {
      backgroundColor: "rgb(255 255 255 / 33%)",
    },
    marginLeft: 0,
    width: "100%",
    // [theme.breakpoints.up("sm")]: {
    //   marginLeft: theme.spacing(0),
    //   width: "auto",
    // },
  },
  searchIcon: {
    padding: "16px",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  searchSidebar: {
    position: "relative",
    margin: 9,
    borderRadius: "4px",
    "&:hover": {
      borderColor: "#a4adb2",
    },
    padding: "2px",
  },

  logo: {
    maxWidth: 50,
    marginRight: "10px",
  },
  SidebarIcon: {
    width: "18px !important",
  },
  //style for footer
  BottomNavigation: {
    padding: "16px 12px 8px",
    fontSize: "12px",
    position: "fixed",
    bottom: 0,
    color: "#998d8d",
  },
  cardHeader: {
    padding: "4px 2px",
  },
  cardContent: {
    padding: "0px 2px !important",
  },
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer,
  backgroundColor: "rgba(235, 235, 235, 0.87)",
  color: "rgba(0,0,0,0.87)",
  width: `calc(100% - ${theme.spacing(5.5)})`,
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: 350,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeInOut,
      duration: 350,
    }),
  }),
}));

export const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  position: "fixed",
  height: "100vh",
  padding: "2px 4px",
  overflow: "hidden",
  top: "84px",
  width: `calc(99.5% - ${theme.spacing(5.5)})`,
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: 350,
  }),
  marginLeft: `calc(${theme.spacing(5.5)})`,
  ...(open && {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeInOut,
      duration: 350,
    }),
    marginLeft: `${drawerWidth}px`,
    width: `calc(99.5% - ${drawerWidth}px)`,
  }),
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const openedMixin = (theme: Theme): CSSObject => ({
  position: "relative",
  overflowX: "hidden",
  whiteSpace: "nowrap",
  height: "100vh",
  // background: "linear-gradient(56deg, #09203f, #537895)",
  // background: "linear-gradient(56deg, #80ced7, #007ea0)",
  // background: "linear-gradient(56deg, #37d5d6, #36096d)",
  // background: "linear-gradient(56deg, #407ec2, #0c96c5)",
  // background: "linear-gradient(56deg, #5dcd81, #238878)",
  // background: "linear-gradient(0deg, #1E5B87 , #36287D)",
  background: "linear-gradient( #1E5B87 , #36287D)",
  borderRight: "none !important",
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.easeInOut,
    duration: 350,
  }),
});

const closedMixin = (theme: Theme): CSSObject => ({
  position: "relative",
  overflowX: "hidden",
  height: "100vh",
  width: `calc(${theme.spacing(5.5)})`,
  // background: "linear-gradient(56deg, #09203f, #537895)",
  // background: "linear-gradient(56deg, #80ced7, #007ea0)",
  // background: "linear-gradient(56deg, #37d5d6, #36096d)",
  // background: "linear-gradient(56deg, #407ec2, #0c96c5)",
  // background: "linear-gradient(56deg, #5dcd81, #238878)",
  // background: "linear-gradient(0deg, #1E5B87 , #36287D)",
  background: "linear-gradient(#1E5B87 , #36287D)",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.easeInOut,
    duration: 350,
  }),
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const Search = styled("div")(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  height: "90%",
  position: "relative",
  marginLeft: "2px",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    paddingLeft: `calc(1em + ${theme.spacing(1)})`,
    paddingTop: 6,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));
