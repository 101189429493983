import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const professionalModURL = "api/v1/customer-app/professional";

//get Professional data api
export const getProfessional = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${professionalModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get Professional by Id
export const getProfessionalById = (slug: string) => {
  return axiosInstance.get(`${professionalModURL}/${slug}`);
};

//handle search
export const searchProfessional = ({ rowsPerPage, search }: searchProps) => {
  return `${professionalModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create Professional
export const createProfessional = (body: string | FormData) =>
  axiosInstance.post(`${professionalModURL}`, body);
//update Professional
export const updateProfessional = (body: string | FormData, slug: string) =>
  axiosInstance.patch(`${professionalModURL}/${slug}`, body);

//get professional categories
export const getProfessionalCategory = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${professionalModURL}/category?offset=0&limit=${rowsPerPage}`
  );
};
//get professional tags
export const getProfessionalTag = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${professionalModURL}/tag?offset=0&limit=${rowsPerPage}`
  );
};
