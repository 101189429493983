import { axiosInstance } from "../../../../AppUtils/Utils/axios"

export const dashboardURL = '/api/v1/dashboard/company'

// company
export const companyWise = (id: number) =>{
    return axiosInstance.get(`${dashboardURL}/${id}`)
}

// category sub type wise
export const categorySubTypeWise = () =>{
    return axiosInstance.get(`${dashboardURL}/category-subtype-wise`)
}

// category wise
export const categoryWise  = () =>{
    return axiosInstance.get(`${dashboardURL}/category-wise`)
}

// listing type wise
export const listingTypeWise = () =>{
    return axiosInstance.get(`${dashboardURL}/listing-type-wise`)
}

// sector wise
export const sectorWise = () =>{
    return axiosInstance.get(`${dashboardURL}/sector-wise`)
}

// status wise
export const statusWise = () =>{
    return axiosInstance.get(`${dashboardURL}/status-wise`)
}

// sub category wise
export const subCategoryWise = () =>{
    return axiosInstance.get(`${dashboardURL}/sub-category-wise`)
}

// user type wise
export const userTypeWise = () =>{
    return axiosInstance.get(`${dashboardURL}/user-type-wise`)
}