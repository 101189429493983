import { filterApiData } from '../../../../AppUtils/Utils/appFunctions';
import { axiosInstance } from '../../../../AppUtils/Utils/axios';
import { getApiProps, searchProps } from '../../../../AppUtils/Utils/globalTypes';

export const userAppModURL = 'api/v1/user-app';

export const getPublicUser = (data: getApiProps) => {
	const { rowsPerPage, page } = data;
	return axiosInstance.get(
		`${userAppModURL}/public-user-list?offset=${
			rowsPerPage * page
		}&limit=${rowsPerPage}${
			data?.filterData ? filterApiData(data?.filterData) : ''
		}`
	);
};

// get public user by Id
export const viewPublicUserById = (id: number) => {
  return axiosInstance.get(`${userAppModURL}/public-user-list?id=${id}`);
};

//handle search
export const searchPublicUser = ({ rowsPerPage, search }: searchProps) => {
  return `${userAppModURL}/public-user-list?offset=0&limit=${rowsPerPage}&search=${search}`;
};
