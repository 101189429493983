import {
  createSliderFail,
  createSliderRequest,
  createSliderSuccess,
  //
  getSliderRequest,
  getSliderFail,
  getSliderSuccess,
  //
  updateSliderRequest,
  updateSliderFail,
  updateSliderSuccess,
  //
  getSliderNextRequest,
  getSliderPreviousRequest,
  //
  clearSliderData,
  getSliderByIdRequest,
  sliderEditSuccess,
  sliderEditFail,
} from "./sliderSlice";
import { mergeMap } from "rxjs";
import { createSlider, getSlider, getSliderById, updateSlider } from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get slider epic
const getSliderEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSliderRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getSlider(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getSliderSuccess(action?.payload) : getSliderFail()
    )
  );

//get slider by id
const getSliderByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSliderByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getSliderById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? sliderEditSuccess(action?.payload) : sliderEditFail()
    )
  );

//get next
const getSliderNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSliderNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getSliderSuccess(action?.payload) : getSliderFail()
    )
  );

//get previous
const getSliderPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSliderPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getSliderSuccess(action?.payload) : getSliderFail()
    )
  );

//create slider epic
const createSliderEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createSliderRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      const { title, info, is_active, image_md, image_sm, display_order } = values;
      try {
        const body = new FormData();
        body.append("title", title);
        body.append("display_order", display_order);
        body.append("info", info);
        body.append("is_active", is_active);
        body.append("image_md", image_md);
        body.append("image_sm", image_sm);

        const response = await createSlider(body);
        if (response) {
          dispatch(getSliderRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createSliderSuccess() : createSliderFail();
    })
  );

//update slider epic
const updateSliderEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateSliderRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      const { title, info, is_active, image_md, image_sm, remarks, display_order } = values;

      try {
        let body = new FormData();
        body.append("title", title);
        body.append("display_order", display_order);
        body.append("info", info);
        body.append("is_active", is_active);
        body.append("image_md", image_md);
        body.append("image_sm", image_sm);
        body.append("remarks", remarks);
        const response = await updateSlider(body, id);
        if (response) {
          dispatch(getSliderRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearSliderData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateSliderSuccess() : updateSliderFail()
    )
  );

export const sliderEpics = combineEpics(
  getSliderEpic,
  createSliderEpic,
  updateSliderEpic,
  getSliderNext,
  getSliderPrevious,
  getSliderByIdEpic
);
