import React from "react";
import {useHistory} from 'react-router-dom'
import PropTypes from "prop-types";
import { useState } from "react";
import { Dispatch, SetStateAction } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  Collapse,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
//
import MenuItemList from "./MenuItem";
import { useStyles } from "./SidebarStyles";
import "./Sidebar.css";
import { useAppDispatch, useAppSelector } from "../../../AppUtils/Utils/appHooks";
import { loginSelector } from "../../../Pages/Login/Redux/selector";
import { clearSearchedData } from "../../../Pages/CommonAppRedux/CommonAppSlice";

// React runtime PropTypes
export const AppMenuItemPropTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
  permission: PropTypes.array,
  Icon: PropTypes.elementType,
  items: PropTypes.array,
  className: PropTypes.any,
};
type AppMenuItemProp = PropTypes.InferProps<typeof AppMenuItemPropTypes>;
type AppMenuItemPropsWithoutItems = Omit<AppMenuItemProp, "items">;

// Improve child items declaration
export type AppMenuItemProps = AppMenuItemPropsWithoutItems & {
  items?: any;
  search: string;
  openSubMenu: boolean;
  setOpenSubMenu: Dispatch<SetStateAction<boolean>>;
  currentIndex?: number;
};
const Sidebar: React.FC<AppMenuItemProps> = (props) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  let showSubMenu: any;
  const { permissions, is_superuser } = useAppSelector(loginSelector);
  const {
    name,
    link,
    Icon,
    items,
    className,
    search = "",
    currentIndex,
  } = props;
  const classes = useStyles();
  const isExpandable = items && items.length > 0;
  const [openSubMenu, setOpenSubMenu] = useState(false);
 
  const MenuItemRoot = (
    <>
      {search === "" ? (
        <>
          <MenuItemList
            className={className}
            link={link}
            onClick={() => {
              setOpenSubMenu(!openSubMenu);
            }}
            currentIndex={currentIndex!}
          >
            {/* For Displaying of an icon in sidebar*/}
            {Icon && (
              <ListItemIcon className={classes.Iconbg}>
                <Icon className={classes.SidebarIcon} />
              </ListItemIcon>
            )}
            {/* for displaying of name in sidebar */}
            {<ListItemText primary={name} />}
            {/* Display the expand menu if the item has children */}
            {isExpandable && !openSubMenu && <ExpandMoreIcon />}
            {isExpandable && openSubMenu && <ExpandLessIcon />}
          </MenuItemList>
        </>
      ) : (
        //items is all list present ex user setup has items user and user role and each item inside has name, link, image and permission
        items?.map((item: any, index: number) => {
          const { name } = item;
          return (
            <React.Fragment key={index}>
              {name?.toLowerCase()?.includes(search?.toLowerCase()) && (
                <React.Fragment key={index}>
                  <Sidebar
                    {...item}
                    key={index}
                    className={classes.subMenuPadding}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          );
        })
      )}
    </>
  );

  const handlePageNavigation= (link: string) =>{
    dispatch(clearSearchedData())
    history.push(link)
  }

  
  // for child
  const MenuItemChildren =
    search === "" ? (
      isExpandable ? (
        <Collapse in={openSubMenu}>
          <Divider />
          <List component="div" className={classes.sidebarChildrenList}>
            {items?.map((item: any, index: number) => {
              const { permission, indexValue } = item;
              showSubMenu = permissions?.some((element: any) =>
                permission?.includes(element.code_name)
              );
              return (
                (is_superuser || showSubMenu) && (
                  <React.Fragment key={index}>
                    <MenuItemList
                      className={classes.subMenuPadding}
                      // link={item?.link}
                      onClick={()=>handlePageNavigation(item?.link)}
                      currentIndex={indexValue}
                      
                    >
                      {/* For Displaying of an icon in sidebar*/}
                      {/* for displaying of name in sidebar */}
                      {<ListItemText primary={item?.name} />}
                      {/* Display the expand menu if the item has children */}
                    </MenuItemList>
                  </React.Fragment>
                )
              );
            })}
          </List>
        </Collapse>
      ) : null
    ) : null;

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  );
};

export default Sidebar;
