import { createSlice } from "@reduxjs/toolkit";
import { cropperToolProps } from "./types";

const initialState: cropperToolProps = {
  isCrop: false,
  previewImage: "",
  croppedImage: null,
  file: null,
  showCropperModal: false,
};

const cropperToolSlice = createSlice({
  name: "cropper",
  initialState,
  reducers: {
    openCropperModal: (state, { payload }) => {
      state.showCropperModal = payload;
    },
    setIsCrop: (state, { payload }) => {
      state.isCrop = payload;
    },
    setPreviewImage: (state, { payload }) => {
      state.previewImage = payload;
      // state.file = payload
    },
    setCroppedImage: (state, { payload }) => {
      state.croppedImage = payload;
    },
    setFile: (state, { payload }) => {
      state.file = payload;
    },
  },
});

export const {
  // Open Cropper Modal
  openCropperModal,
  setIsCrop,
  setPreviewImage,
  setCroppedImage,
  setFile,
} = cropperToolSlice.actions;
export default cropperToolSlice.reducer;
