import { createSlice } from "@reduxjs/toolkit";
import { initialStateSector } from "./types";
const initialState: initialStateSector = {
  sectors: [],
  loadingSector: false,
  edit: false,
  loadingUpdateSector: false,
  sector: null,
  count: 0,
  previous: "",
  next: "",
};

export const Sector = createSlice({
  name: "SectorReducer",
  initialState,
  reducers: {
    loadingSector: (state) => {
      state.loadingSector = true;
    },
    getSectorRequest: (state, payload) => {
      state.loadingSector = true;
    },
    getSectorSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingSector = false;
      state.sectors = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getSectorFail: (state) => {
      state.loadingSector = false;
    },
    //
    getSectorNextRequest: (state, payload) => {
      state.loadingSector = true;
    },
    getSectorPreviousRequest: (state, payload) => {
      state.loadingSector = true;
    },
    //
    getSectorByIdRequest: (state, payload) => {
      state.edit = true;
    },
    editSectorSuccess: (state, { payload }) => {
      state.sector = payload;
    },
    editSectorFail: (state) => {
      state.edit = false;
    },
    //
    updateSectorRequest: (state, payload) => {
      state.loadingUpdateSector = true;
    },
    updateSectorSuccess: (state) => {
      state.loadingUpdateSector = false;
    },
    updateSectorFail: (state) => {
      state.loadingUpdateSector = false;
    },
    //
    clearSectorData: (state) => {
      state.sector = null;
      state.edit = false;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingSector,
  getSectorSuccess,
  getSectorFail,
  getSectorRequest,
  //
  getSectorNextRequest,
  getSectorPreviousRequest,
  //
  getSectorByIdRequest,
  editSectorSuccess,
  editSectorFail,
  //
  updateSectorRequest,
  updateSectorSuccess,
  updateSectorFail,
  //
  clearSectorData,
} = Sector.actions;
export default Sector.reducer;
