import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const investorProfileModURL = "api/v1/customer-app/investor-profile";

//get investorProfile data api
export const getInvestorProfile = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${investorProfileModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get InvestorProfile by Id
export const getInvestorProfileById = (slug: string) => {
  return axiosInstance.get(`${investorProfileModURL}/${slug}`);
};

//handle search
export const searchInvestorProfile = ({ rowsPerPage, search }: searchProps) => {
  return `${investorProfileModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create InvestorProfile
export const createInvestorProfile = (body: string | FormData) =>
  axiosInstance.post(`${investorProfileModURL}`, body);
//update InvestorProfile
export const updateInvestorProfile = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${investorProfileModURL}/${id}`, body);