import { Switch } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";
import { lazyWithReload } from "./Utils";

const User = lazyWithReload(
  () => import("../Pages/CoreSetupPages/User/UserPages")
);
const UserRole = lazyWithReload(
  () => import("../Pages/CoreSetupPages/UserRole/UserRolePages")
);
const Sector = lazyWithReload(
  () => import("../Pages/CoreSetupPages/SectorSetup/SectorSetupPages")
);
const SubCategory = lazyWithReload(
  () => import("../Pages/CoreSetupPages/SubCategory/SubCategoryPages")
);
const CategorySubType = lazyWithReload(
  () => import("../Pages/CoreSetupPages/CategorySubType/CategorySubTypePages")
);
const Category = lazyWithReload(
  () => import("../Pages/CoreSetupPages/Category/Category")
);
const ProfessionalSetup = lazyWithReload(
  () =>
    import("../Pages/CoreSetupPages/ProfessionalSetup/ProfessionalSetupPages")
);
const InvestorProfile = lazyWithReload(
  () => import("../Pages/CoreSetupPages/InvestorProfile/InvestorProfilePages")
);
const Organization = lazyWithReload(
  () =>
    import("../Pages/CoreSetupPages/OrganizationSetup/OrganizationSetupPages")
);
const Slider = lazyWithReload(
  () => import("../Pages/CoreSetupPages/HomepageSlider/SliderPages")
);
const Profile = lazyWithReload(() => import("../Pages/Profile"));
const Company = lazyWithReload(
  () => import("../Pages/CoreSetupPages/Company/CompanyPages")
);
const Dashboard = lazyWithReload(
  () => import("../Pages/CoreSetupPages/Dashboard/DashboardPages")
);
const PublicUser = lazyWithReload(
  () => import("../Pages/CoreSetupPages/PublicUserList/PublicUserPages")
);
const Blog = lazyWithReload(
  () => import("../Pages/CoreSetupPages/Blog/BlogPages")
);
const BlogTag = lazyWithReload(
  () => import("../Pages/CoreSetupPages/BlogTag/TagPages")
);
const BlogCategory = lazyWithReload(
  () => import("../Pages/CoreSetupPages/BlogCategory/CategoryPages")
);
const Contact = lazyWithReload(
  () => import("../Pages/CoreSetupPages/ContactListing/ContactPages")
);
const ChangePassword = lazyWithReload(
  () => import("../Pages/Password/PasswordPages")
);

// lab components imports
const CoreSetupRoutes = () => {
  return (
    <>
      <Switch>
        <ProtectedRoutes
          exact
          path="/core-setup/category-setup"
          component={Category}
          permission={["view_category", "add_category", "update_category"]}
        />
        <ProtectedRoutes exact path="/" component={Profile} permission="" />
        <ProtectedRoutes
          exact
          path="/core-setup/category-sub-type-setup"
          component={CategorySubType}
          permission={[
            "view_category_sub_type_setup",
            "add_category_sub_type_setup",
            "update_category_sub_type_setup",
          ]}
        />
        <ProtectedRoutes
          exact
          path="/core-setup/sub-category-setup"
          component={SubCategory}
          permission={[
            "view_sub_category_setup",
            "add_sub_category_setup",
            "update_sub_category_setup",
          ]}
        />
        <ProtectedRoutes
          exact
          path="/core-setup/sector-setup"
          component={Sector}
          permission={[
            "view_sector_setup",
            "add_sector_setup",
            "update_sector_setup",
          ]}
        />
        <ProtectedRoutes
          exact
          path="/core-setup/user"
          component={User}
          permission={[
            "view_user",
            "add_user",
            "update_user",
            // "change_user_password",
          ]}
        />
        <ProtectedRoutes
          exact
          path="/core-setup/user-group"
          component={UserRole}
          permission={["view_user_role", "add_user_role", "update_user_role"]}
        />
        <ProtectedRoutes
          exact
          path="/core-setup/organization-setup"
          component={Organization}
          permission={[
            "view_organization_setup",
            "add_organization_setup",
            "update_organization_setup",
          ]}
        />
        <ProtectedRoutes
          exact
          path="/core-setup/dashboard"
          component={Dashboard}
          permission={[
            "view_dashboard",
            // "add_organization_setup",
            // "update_organization_setup",
          ]}
        />
        <ProtectedRoutes
          exact
          path="/core-setup/home-page-slider-setup"
          component={Slider}
          permission={["view_homepage", "add_homepage", "update_homepage"]}
        />
        <ProtectedRoutes
          exact
          path="/core-setup/professional-setup"
          component={ProfessionalSetup}
          permission={[
            "view_professional",
            "add_professional",
            "update_professional",
          ]}
        />
        <ProtectedRoutes
          exact
          path="/core-setup/investor-profile"
          component={InvestorProfile}
          permission={[
            "view_investor_profile",
            "add_investor_profile",
            "update_investor_profile",
          ]}
        />
        <ProtectedRoutes
          exact
          path="/core-setup/contact-request"
          component={Contact}
          permission={["view_contact_form"]}
        />
        <ProtectedRoutes
          exact
          path="/core-setup/public-user"
          component={PublicUser}
          permission={["view_public_user_list"]}
        />
        <ProtectedRoutes
          exact
          path="/core-setup/companies"
          component={Company}
          permission={["view_companies", "add_companies", "update_companies"]}
        />
        <ProtectedRoutes
          exact
          path="/change-password"
          component={ChangePassword}
          permission={["change_user_password"]}
        />
        <ProtectedRoutes
          exact
          path="/core-setup/blog"
          component={Blog}
          permission={[""]}
        />
        <ProtectedRoutes
          exact
          path="/core-setup/blog-tag"
          component={BlogTag}
          permission={[""]}
        />
        <ProtectedRoutes
          exact
          path="/core-setup/blog-category"
          component={BlogCategory}
          permission={[""]}
        />
        {/* <Redirect from="/" to="/" exact /> */}
        {/* <Route path="*" component={Errorpage} /> */}
      </Switch>
    </>
  );
};

export default CoreSetupRoutes;
