import React from 'react'
function setCookie(name:any, value:any, options = {}) {
    options = {
      path: '/',
      // add other defaults here if necessary
      ...options
    };
  //@ts-ignore
    // if (options.expires instanceof Date) {
    //   options.expires = options.expires.toUTCString();
    // }
  
    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
  
    for (let optionKey in options) {
      updatedCookie += "; " + optionKey;
      //@ts-ignore
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += "=" + optionValue;
      }
    }
  
    document.cookie = updatedCookie;
  }

export default setCookie