import {
  createInvestorProfileFail,
  createInvestorProfileRequest,
  createInvestorProfileSuccess,
  //
  getInvestorProfileRequest,
  getInvestorProfileFail,
  getInvestorProfileSuccess,
  //
  updateInvestorProfileRequest,
  updateInvestorProfileFail,
  updateInvestorProfileSuccess,
  //
  getInvestorProfileNextRequest,
  getInvestorProfilePreviousRequest,
  //
  clearInvestorProfileData,
  getInvestorProfileByIdRequest,
  investorProfileEditSuccess,
  investorProfileEditFail,
} from "./investorProfileSlice";
import { mergeMap } from "rxjs";
import {
  createInvestorProfile,
  getInvestorProfile,
  getInvestorProfileById,
  updateInvestorProfile,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import messages from "../../../../AppUtils/Utils/validationConstants";
export const controller = new AbortController();

//get investorProfile epic
const getInvestorProfileEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getInvestorProfileRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getInvestorProfile(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getInvestorProfileSuccess(action?.payload)
        : getInvestorProfileFail()
    )
  );

//get investorProfile by id
const getInvestorProfileByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getInvestorProfileByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getInvestorProfileById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? investorProfileEditSuccess(action?.payload)
        : investorProfileEditFail()
    )
  );

//get next
const getInvestorProfileNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getInvestorProfileNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getInvestorProfileSuccess(action?.payload)
        : getInvestorProfileFail()
    )
  );

//get previous
const getInvestorProfilePrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getInvestorProfilePreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getInvestorProfileSuccess(action?.payload)
        : getInvestorProfileFail()
    )
  );

//create investorProfile epic
const createInvestorProfileEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createInvestorProfileRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      const {
        photo,
        ...nonBinaryData
      } = values;
      try {
        const body = new FormData();

        if (photo) {
          body.append("photo", photo);
        }
        for (let [key, value] of Object.entries(nonBinaryData)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }

        const response = await createInvestorProfile(body);
        if (response) {
          dispatch(getInvestorProfileRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload
        ? createInvestorProfileSuccess()
        : createInvestorProfileFail();
    })
  );

//update investorProfile epic
const updateInvestorProfileEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateInvestorProfileRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      const {
        photo,
        ...nonBinaryData
      } = values;

      try {
        let body = new FormData();

        // if (photo) {
        //   body.append("photo", photo);
        // }
        for (let [key, value] of Object.entries(values)) {
          if(key === 'photo' && value === null){
            continue;
          }
          // @ts-ignore
          body.append(`${key}`, value);
        }

        const response = await updateInvestorProfile(body, id);
        if (response) {
          dispatch(getInvestorProfileRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearInvestorProfileData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateInvestorProfileSuccess() : updateInvestorProfileFail()
    )
  );

export const investorProfileEpics = combineEpics(
  getInvestorProfileEpic,
  createInvestorProfileEpic,
  updateInvestorProfileEpic,
  getInvestorProfileNext,
  getInvestorProfilePrevious,
  getInvestorProfileByIdEpic
);
