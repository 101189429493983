import { createSlice } from '@reduxjs/toolkit';
import { NotificationProps } from './types';

const initialState: NotificationProps = {
	loading: false,
	notifications: [],
    loadingNotificationCount: false,
    unread_notification: 0,
    total_notification: 0,
    loadingReadNotification: false,

};

const notificationSlice = createSlice({
	name: 'notification',
	initialState,
	reducers: {
		// Get Notification
		getNotificationRequest: (state) => {
			state.loading = true;
		},
		getNotificationSuccess: (state, { payload: { results } }) => {
			state.loading = false;
			state.notifications = results;
		},
		getNotificationFail: (state) => {
			state.loading = false;
		},

		// get all notification
		getAllNotificationRequest: (state) => {
			state.loading = true;
		},
		getAllNotificationSuccess: (
			state,
			{ payload: { results } }
		) => {
			state.loading = false;
			state.notifications = results;
		},
		getAllNotificationFail: (state) => {
			state.loading = false;
		},

        // get notification count
		getNotificationCountRequest: (state) => {
			state.loadingNotificationCount = true;
		},
		getNotificationCountSuccess: (state, { payload }) => {
			state.unread_notification = payload.unread_count;
			state.total_notification = payload.total_count;
			state.loadingNotificationCount = false;
		},
		getNotificationCountFail: (state) => {
			state.loadingNotificationCount = false;
		},

        // read notification
		readNotificationRequest: (state, action)=>{
			state.loadingReadNotification = true
		},
		readNotificationSuccess: (state, {payload})=>{
			state.loadingReadNotification = false
		},
		readNotificationFail: (state)=>{
			state.loadingReadNotification = false
		},

		// read All notification
		readAllNotificationRequest: (state)=>{
			state.loadingReadNotification = true
		},
		readAllNotificationSuccess: (state, {payload})=>{
			state.loadingReadNotification = false
		},
		readAllNotificationFail: (state)=>{
			state.loadingReadNotification = false
		},
	},
});

export const {
	// get Notification
	getNotificationFail,
	getNotificationRequest,
	getNotificationSuccess,
    // get All notification
    getAllNotificationFail, 
    getAllNotificationRequest, 
    getAllNotificationSuccess, 
    // get notification count
    getNotificationCountFail,
    getNotificationCountRequest,
    getNotificationCountSuccess,
    // read notification
    readNotificationFail,
    readNotificationRequest,
    readNotificationSuccess,
    // read all notification
    readAllNotificationFail,
    readAllNotificationRequest,
    readAllNotificationSuccess,
} = notificationSlice.actions;
export default notificationSlice.reducer;
