import {
  getBlogSuccess,
  getBlogFail,
  loadingBlog,
  getBlogRequest,
  //
  getBlogNextRequest,
  getBlogPreviousRequest,
  //
  getBlogByIdRequest,
  editBlogSuccess,
  editBlogFail,
  //
  clearBlogData,
  //
  updateBlogRequest,
  updateBlogSuccess,
  updateBlogFail,
  //
  createBlogRequest,
  createBlogSuccess,
  createBlogFail,
  //
  getBlogCategoryRequest,
  getBlogCategorySuccess,
  getBlogCategoryFail,
  //
  getBlogTagRequest,
  getBlogTagSuccess,
  getBlogTagFail,
  getBlogAuthorRequest,
  getBlogAuthorSuccess,
  getBlogAuthorFail,
  //
} from "./blogSlice";
import { mergeMap } from "rxjs";
import {
  createBlog,
  getBlog,
  getBlogAuthor,
  getBlogById,
  getBlogCategory,
  getBlogTag,
  updateBlog,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import messages from "../../../../AppUtils/Utils/validationConstants";
import { tag } from "./types";

export const controller = new AbortController();

// get Blog
const getBlogEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBlogRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingBlog());
      try {
        const response = await getBlog(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getBlogSuccess(action?.payload) : getBlogFail()
    )
  );

//get blog by id
const getBlogByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBlogByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getBlogById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? editBlogSuccess(action?.payload) : editBlogFail()
    )
  );

//get blog category
const getBlogCategoryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBlogCategoryRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getBlogCategory(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getBlogCategorySuccess(action?.payload)
        : getBlogCategoryFail()
    )
  );

//get blog author
const getBlogAuthorEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBlogAuthorRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getBlogAuthor(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getBlogAuthorSuccess(action?.payload)
        : getBlogAuthorFail()
    )
  );

//get blog tag
const getBlogTagEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBlogTagRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getBlogTag(action?.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getBlogTagSuccess(action?.payload) : getBlogTagFail()
    )
  );

//get next
const getBlogNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBlogNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getBlogSuccess(action?.payload) : getBlogFail()
    )
  );

//get previous
const getBlogPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getBlogPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getBlogSuccess(action?.payload) : getBlogFail()
    )
  );

//create blog epic
const createBlogEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(createBlogRequest.match),
    mergeMap(async ({ payload: { values, rowsPerPage, page } }) => {
      try {
        const { tags, post_faqs, ...restValues } = values;
        const body = new FormData();
        for (let [key, value] of Object.entries(restValues)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }
        for (let x in tags) {
          body.append(`tags[${x}]`, tags[x]?.id);

          body.append(`tags[${x}]`, tags[x]?.name);
        }

        if (post_faqs && post_faqs.length > 0) {
          post_faqs.forEach((faq: any, index: any) => {
            body.append(`post_faqs[${index}][title]`, faq.title);
            body.append(`post_faqs[${index}][description]`, faq.description);
          });
        } else {
          body.append('post_faqs', JSON.stringify([]));
        }


        const response = await createBlog(body);
        if (response) {
          dispatch(getBlogRequest({ rowsPerPage, page }));
          dispatch(alertSuccessAction(messages.createMessage));
          dispatch(closeModal());
        }
        return { payload: { response } };
      } catch (e) {
        dispatch(alertErrorAction(messages.createFailMessage));
        return { error: e };
      }
    }),
    map((action) => {
      return action?.payload ? createBlogSuccess() : createBlogFail();
    })
  );

//update blog epic
const updateBlogEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateBlogRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      try {
        const { tags, post_faqs, ...restValues } = values;
        const body = new FormData();
        for (let [key, value] of Object.entries(restValues)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }
        if (tags && tags.length > 0) {
          tags.forEach((tag: any, index: any) => {
            body.append(`tags[${index}]`, tag.id);
            body.append(`tags[${index}]`, tag.name);
          });

        }


        if (post_faqs && post_faqs.length > 0) {
          post_faqs.forEach((faq: any, index: any) => {
            if (faq.id) {
              body.append(`post_faqs[${index}][id]`, faq.id);
            }
            body.append(`post_faqs[${index}][title]`, faq.title);
            body.append(`post_faqs[${index}][description]`, faq.description);
          });
        }
        else {
          body.append('post_faqs', JSON.stringify([]));
        }

        const response = await updateBlog(body, id);
        if (response) {
          dispatch(getBlogRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearBlogData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) => (action?.payload ? updateBlogSuccess() : updateBlogFail()))
  );
export const blogEpics = combineEpics(
  getBlogEpic,
  getBlogNext,
  getBlogPrevious,
  createBlogEpic,
  getBlogByIdEpic,
  getBlogCategoryEpic,
  getBlogTagEpic,
  updateBlogEpic,
  getBlogAuthorEpic
);
