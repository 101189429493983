import React, { useEffect, useState } from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import { Grid, List, ListItem, Stack, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../AppUtils/Utils/appHooks';
import {
	getAllNotificationRequest,
	getNotificationCountRequest,
	getNotificationRequest,
	readAllNotificationRequest,
	readNotificationRequest,
} from './Redux/notificationSlice';
import { notificationSelector } from './Redux/selector';
import { defaultRowsPerPage } from '../../AppUtils/Utils/globalConstant';
import { getTimeago, sortByProperty } from '../../AppUtils/Utils/appFunctions';

const NotificationModal = () => {
	const dispatch = useAppDispatch();
	const [page, setPage] = useState(1);
	const { notifications, unread_notification, total_notification } =
		useAppSelector(notificationSelector);
	const [sortedNotifications, setSortedNotifications] = useState<any>(null)
		useEffect(() => {
			if (notifications?.length > 0) {
				let sortNotifications = [...notifications];
		
				sortNotifications = sortByProperty(sortNotifications, 'id', false);
				setSortedNotifications(sortNotifications)
			}
		}, [notifications]);
	useEffect(() => {
		dispatch(getNotificationRequest());
		dispatch(getNotificationCountRequest());
	}, [dispatch]);

	const handleReadAll = () => {
		dispatch(readAllNotificationRequest());
	};

	const handleReadNotification = (id: number) => {
		dispatch(readNotificationRequest({ id }));
	};
	return (
		<>
			<Grid
				container
				justifyContent="space-between"
				flexWrap="nowrap"
				px={1}
				py={1}
				sx={{
					borderBottom: '1px solid #000',
				}}>
				<Grid container alignItems="center">
					<Stack
						direction="row"
						columnGap={0.5}
						alignItems="center"
						mr={1}>
						<MailOutlineIcon sx={{ fontSize: '18px' }} />
						<Typography component="span">unread</Typography>
					</Stack>
					<Stack direction="row" columnGap={0.5} alignItems="center">
						<DraftsOutlinedIcon sx={{ fontSize: '18px' }} />
						<Typography component="span">read</Typography>
					</Stack>
				</Grid>
				<Grid>
					<Typography
						component="p"
						onClick={handleReadAll}
						sx={{
							cursor: 'pointer',
							width: 'max-content',
						}}>
						Read All
					</Typography>
				</Grid>
			</Grid>
			<List
				sx={{
					padding: '0',
					maxHeight: '280px',
					overflow: 'auto',
					cursor: 'pointer',
				}}>
				{sortedNotifications?.map((notification: any, index: number) => {
					return (
						<ListItem
							onClick={() =>
								handleReadNotification(
									notification?.notification_id
								)
							}
							key={index}
							sx={{
								minWidth: '250px',
								maxWidth: '250px',
								bgcolor: !notification?.is_read
									? '#0000FF30'
									: 'none',
								width: '100%',
								transition: 'all .3s ease',
								'&:hover': {
									bgcolor: '#0000FF10',
								},
								'&:not(:last-child)': {
									borderBottom: '1px solid #aaa',
								},
							}}>
							<Stack sx={{ width: '100%' }}>
								{/* <Typography
								component="h3"
								sx={{
									fontWeight: 'bold',
									fontSize: '16px',
								}}>
								This is a title
							</Typography> */}
								<Typography
									component="p"
									sx={{
										lineHeight: '1.1',
										fontSize: '15px',
										fontWeight: '400',
									}}>
									{notification?.msg}
								</Typography>
								<Stack
									mt={0.5}
									direction="row"
									justifyContent="space-between"
									alignItems="center">
									<Typography
										component="span"
										sx={{
											fontWeight: '500',
											fontSize: '15px',
										}}>
										{getTimeago(
											notification?.created_date_ad
										)}
									</Typography>
									{notification?.is_read ? (
										<DraftsOutlinedIcon
											sx={{
												fontSize: '18px',
											}}
										/>
									) : (
										<MailOutlineIcon
											sx={{ fontSize: '18px' }}
										/>
									)}
								</Stack>
							</Stack>
						</ListItem>
					);
				})}
			</List>
		</>
	);
};

export default NotificationModal;
