import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { searchProps, getApiProps } from "../../../../AppUtils/Utils/globalTypes";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";

const itemListDetailModURL = `api/v1/shortcut-app/pharmacy-item`;

//get itmelist
export const getItemListDetail = (data: getApiProps) => {
    const { rowsPerPage, page, search } = data;
    return axiosInstance.get(`${itemListDetailModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}&search=${search ?? ""}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
    );
};
//get itmelist 
export const getItemListDetailById = (id: number) => {
  return axiosInstance.get(`${itemListDetailModURL}/${id}`);
}
//search itmelist
export const searchItemListDetail = ({ rowsPerPage, search}: searchProps) => {
    return `${itemListDetailModURL}?offset=0&limit=${rowsPerPage}&search=${search ?? ""}`
}