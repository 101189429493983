import { createSlice } from "@reduxjs/toolkit";
import { initialStateSubCategory } from "./types";
const initialState: initialStateSubCategory = {
  subCategories: [],
  loadingSubCategory: false,
  loadingUpdateSubCategory: false,
  edit: false,
  subCategory: null,
  count: 0,
  previous: "",
  next: "",
};

export const SubCategory = createSlice({
  name: "SubCategoryReducer",
  initialState,
  reducers: {
    loadingSubCategory: (state) => {
      state.loadingSubCategory = true;
    },
    getSubCategoryRequest: (state, payload) => {
      state.loadingSubCategory = true;
    },
    getSubCategorySuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingSubCategory = false;
      state.subCategories = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getSubCategoryFail: (state) => {
      state.loadingSubCategory = false;
    },
    getSubCategoryNextRequest: (state, payload) => {
      state.loadingSubCategory = true;
    },
    getSubCategoryPreviousRequest: (state, payload) => {
      state.loadingSubCategory = true;
    },
    getSubCategoryByIdRequest: (state, payload) => {
      state.edit = true;
    },
    // edit sub category
    editSubCategorySuccess: (state, { payload }) => {
      state.subCategory = payload;
    },
    editSubCategoryFail: (state) => {
      state.edit = false;
    },
    //
    viewSubCategoryByIdRequest: (state, payload) => {
      state.loadingSubCategory = true;
    },
    viewSubCategorySuccess: (state, { payload }) => {
      state.subCategory = payload;
      state.loadingSubCategory = false;
    },
    viewSubCategoryFail: (state) => {
      state.loadingSubCategory = false;
    },
    //
    updateSubCategoryRequest: (state, payload) => {
      state.loadingUpdateSubCategory = true;
    },
    updateSubCategorySuccess: (state) => {
      state.loadingUpdateSubCategory = false;
    },
    updateSubCategoryFail: (state) => {
      state.loadingUpdateSubCategory = false;
    },
    clearSubCategoryData: (state) => {
      state.subCategory = null;
      state.edit = false;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingSubCategory,
  getSubCategorySuccess,
  getSubCategoryFail,
  getSubCategoryRequest,
  getSubCategoryNextRequest,
  getSubCategoryPreviousRequest,
  //edit category
  getSubCategoryByIdRequest,
  editSubCategorySuccess,
  editSubCategoryFail,
  //
  updateSubCategoryRequest,
  updateSubCategorySuccess,
  updateSubCategoryFail,
  clearSubCategoryData,
  //
  viewSubCategoryByIdRequest,
  viewSubCategorySuccess,
  viewSubCategoryFail,
} = SubCategory.actions;
export default SubCategory.reducer;
