import { getNotificationCountRequest, getNotificationRequest } from "../../Component/Notification/Redux/notificationSlice"
import { store } from "../../Store"



export function notificationWSConnection(accessToken: string){
    const scheme = process.env.REACT_APP_SOCKET_SCHEME
    const url = process.env.REACT_APP_SOCKET_URL
    const socket = new WebSocket(`${scheme}${url}?token=${accessToken}`)

    socket.onopen = function(){
        // websocket connection established
        console.log("Websocket connection established")
    }

    socket.onmessage = function(event){
        const notificationData = JSON.parse(event.data)
        console.log(notificationData, 'notification data')
        store.dispatch(getNotificationRequest())
        store.dispatch(getNotificationCountRequest())

    }

    socket.onclose = function(){
        // websocket connection close
        console.log('Websocket connection closed')
    }
}