import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const sliderModURL = "/api/v1/homepage-app/slider";

//get Slider data api
export const getSlider = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${sliderModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get Slider by Id
export const getSliderById = (id: number) => {
  return axiosInstance.get(`${sliderModURL}/${id}`);
};

//handle search
export const searchSlider = ({ rowsPerPage, search }: searchProps) => {
  return `${sliderModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create Slider
export const createSlider = (body: string | FormData) =>
  axiosInstance.post(`${sliderModURL}`, body);
//update Slider
export const updateSlider = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${sliderModURL}/${id}`, body);
