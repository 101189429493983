import { createSlice } from "@reduxjs/toolkit";
import { initialStateContact } from "./types";
const initialState: initialStateContact = {
  contacts: [],
  loadingContact: false,
  contact: null,
  count: 0,
  previous: "",
  next: "",
};

export const Contact = createSlice({
  name: "ContactReducer",
  initialState,
  reducers: {
    loadingContact: (state) => {
      state.loadingContact = true;
    },
    getContactRequest: (state, payload) => {
      state.loadingContact = true;
    },
    getContactSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingContact = false;
      state.contacts = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getContactFail: (state) => {
      state.loadingContact = false;
    },
    getContactNextRequest: (state, payload) => {
      state.loadingContact = true;
    },
    getContactPreviousRequest: (state, payload) => {
      state.loadingContact = true;
    },
    clearContactData: (state) => {
      state.contact = null;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingContact,
  getContactSuccess,
  getContactFail,
  getContactRequest,
  getContactNextRequest,
  getContactPreviousRequest,
  //
  clearContactData,
} = Contact.actions;
export default Contact.reducer;
