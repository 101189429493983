import { createSlice } from "@reduxjs/toolkit";
import { initialStateInvestorProfile } from "./types";

const initialState: initialStateInvestorProfile = {
  investorProfiles: [],
  loadingInvestorProfile: false,
  loadingCreateInvestorProfile: false,
  edit: false,
  investorProfile: null,
  count: 0,
  previous: "",
  next: "",
};

export const InvestorProfile = createSlice({
  name: "InvestorProfile Reducer",
  initialState,
  reducers: {
    getInvestorProfileRequest: (state, payload) => {
      state.loadingInvestorProfile = true;
    },
    getInvestorProfileSuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingInvestorProfile = false;
      state.investorProfiles = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getInvestorProfileFail: (state) => {
      state.loadingInvestorProfile = false;
    },
    getInvestorProfileNextRequest: (state, payload) => {
      state.loadingInvestorProfile = true;
    },
    getInvestorProfilePreviousRequest: (state, payload) => {
      state.loadingInvestorProfile = true;
    },
    getInvestorProfileByIdRequest: (state, payload) => {
      state.edit = true;
    },
    createInvestorProfileRequest: (state, payload) => {
      state.loadingCreateInvestorProfile = true;
    },
    updateInvestorProfileRequest: (state, payload) => {
      state.loadingCreateInvestorProfile = true;
    },
    createInvestorProfileSuccess: (state) => {
      state.loadingCreateInvestorProfile = false;
    },
    createInvestorProfileFail: (state) => {
      state.loadingCreateInvestorProfile = false;
    },
    investorProfileEditSuccess: (state, { payload }) => {
      state.investorProfile = payload;
    },
    investorProfileEditFail: (state) => {
      state.edit = false;
    },
    updateInvestorProfileSuccess: (state) => {
      state.loadingCreateInvestorProfile = false;
    },
    updateInvestorProfileFail: (state) => {
      state.loadingCreateInvestorProfile = false;
    },
    clearInvestorProfileData: (state) => {
      state.edit = false;
      state.investorProfile = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getInvestorProfileRequest,
  getInvestorProfileSuccess,
  getInvestorProfileFail,
  //
  getInvestorProfilePreviousRequest,
  getInvestorProfileNextRequest,
  //
  createInvestorProfileRequest,
  createInvestorProfileSuccess,
  createInvestorProfileFail,
  //
  investorProfileEditSuccess,
  updateInvestorProfileSuccess,
  updateInvestorProfileRequest,
  investorProfileEditFail,
  updateInvestorProfileFail,
  //
  clearInvestorProfileData,
  getInvestorProfileByIdRequest,
} = InvestorProfile.actions;
export default InvestorProfile.reducer;
