import {
  loginSuccess,
  loginFail,
  // getBranchesSuccess,
  // getBranchesFail,
  // getBranchRequest,
} from "./authSlice";
import messages from "../../../AppUtils/Utils/validationConstants";
import { mergeMap } from "rxjs";
import { getLogin } from "./api";
import { map, filter, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import {
  alertErrorAction,
  alertSuccessAction,
} from "../../CommonAppRedux/CommonAppSlice";
import { loginRequest } from "./authSlice";
import {
  dispatchAction,
  stateAction,
} from "../../../AppUtils/Utils/globalTypes";

//get user epic
const login = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(loginRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getLogin(action?.payload);
        dispatch(alertSuccessAction(messages.loginSuccess));
        return { payload: response.data };
      } catch (e) {
        dispatch(alertErrorAction("Invalid credentials please try again."));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? loginSuccess(action?.payload) : loginFail()
    )
  );
//get user epic
// const getBranches = (
//   action$: Observable<Action>,
//   _: stateAction,
//   { dispatch }: dispatchAction
// ) =>
//   action$.pipe(
//     filter(getBranchRequest.match),
//     mergeMap(async () => {
//       try {
//         const response = await getAllBranches();
//         return { payload: response.data };
//       } catch (e) {
//         dispatch(alertErrorAction(messages.branchFail));
//         return { error: e };
//       }
//     }),
//     map((action) =>
//       action?.payload ? getBranchesSuccess(action?.payload) : getBranchesFail()
//     )
//   );

export const authEpics = combineEpics(login);
