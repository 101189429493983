import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const blogModURL = "api/v1/blog-app";

export const getBlog = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${blogModURL}/post?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

export const createBlog = (body: string | FormData) =>
  axiosInstance.post(`${blogModURL}/post`, body);

//handle search
export const searchBlog = ({ rowsPerPage, search }: searchProps) => {
  return `${blogModURL}/post?offset=0&limit=${rowsPerPage}&search=${search}`;
};

export const updateBlog = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${blogModURL}/post/${id}`, body);
//get blog by id
export const getBlogById = (id: number) =>
  axiosInstance.get(`${blogModURL}/post/${id}`);

//get blog category
export const getBlogCategory = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${blogModURL}/post/category?offset=0&limit=${rowsPerPage}`
  );
};

export const getBlogTag = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${blogModURL}/post/tag?offset=0&limit=${rowsPerPage}`
  );
};

//get blog author
export const getBlogAuthor = (rowsPerPage: number) => {
  return axiosInstance.get(
    `${blogModURL}/post/author?offset=0&limit=${rowsPerPage}`
  );
};