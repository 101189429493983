import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const sectorModURL = "api/v1/category-app/sectors";

//get Sector data api
export const getSector = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${sectorModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get Sector by Id
export const getSectorById = (id: number) => {
  return axiosInstance.get(`${sectorModURL}/${id}`);
}

//handle search
export const searchSector = ({ rowsPerPage, search }: searchProps) => {
  return `${sectorModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create Sector
// export const createSector = (body: string | FormData) =>
//   axiosInstance.post(`${sectorModURL}`, body);
//update Sector
export const updateSector = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${sectorModURL}/${id}`, body);
