import { createSlice } from '@reduxjs/toolkit';
import { DashboardProps } from './types';

const initialState: DashboardProps = {
	loading: false,
	companyWiseData: null,
	categorySubTypeWiseData: [],
	categoryWiseData: [],
	listingTypeWiseData: [],
	sectorWiseData: [],
	subCategoryWiseData: [],
	userTypeWiseData: null,
	statusWiseData: [],
};

const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {
		// company wise
		getCompanyWiseRequest: (state, action) => {
			state.loading = true;
		},
		getCompanyWiseSuccess: (state, { payload }) => {
			state.loading = false;
			state.companyWiseData = payload;
		},
		getCompanyWiseFail: (state) => {
			state.loading = false;
		},
		// category wise
		getCategoryWiseRequest: (state) => {
			state.loading = true;
		},
		getCategoryWiseSuccess: (state, { payload: { results } }) => {
			state.loading = false;
			state.categoryWiseData = results;
		},
		getCategoryWiseFail: (state) => {
			state.loading = false;
		},
		// category sub type wise
		getCategorySubTypeWiseRequest: (state) => {
			state.loading = true;
		},
		getCategorySubTypeWiseSuccess: (state, { payload: { results } }) => {
			state.loading = false;
			state.categorySubTypeWiseData = results;
		},
		getCategorySubTypeWiseFail: (state) => {
			state.loading = false;
		},
		// listing type wise
		getListingTypeWiseRequest: (state) => {
			state.loading = true;
		},
		getListingTypeWiseSuccess: (state, { payload: { results } }) => {
			state.loading = false;
			state.listingTypeWiseData = results;
		},
		getListingTypeWiseFail: (state) => {
			state.loading = false;
		},
		// sector wise
		getSectorWiseRequest: (state) => {
			state.loading = true;
		},
		getSectorWiseSuccess: (state, { payload: { results } }) => {
			state.loading = false;
			state.sectorWiseData = results;
		},
		getSectorWiseFail: (state) => {
			state.loading = false;
		},
		// status wise
		getStatusWiseRequest: (state) => {
			state.loading = true;
		},
		getStatusWiseSuccess: (state, { payload: { results } }) => {
			state.loading = false;
			state.statusWiseData = results;
		},
		getStatusWiseFail: (state) => {
			state.loading = false;
		},
		// sub category wise
		getSubCategoryWiseRequest: (state) => {
			state.loading = true;
		},
		getSubCategoryWiseSuccess: (state, { payload: { results } }) => {
			state.loading = false;
			state.subCategoryWiseData = results;
		},
		getSubCategoryWiseFail: (state) => {
			state.loading = false;
		},
		// user type wise
		getUserTypeWiseRequest: (state) => {
			state.loading = true;
		},
		getUserTypeWiseSuccess: (state, { payload }) => {
			state.loading = false;
			state.userTypeWiseData = payload;
		},
		getUserTypeWiseFail: (state) => {
			state.loading = false;
		},
	},
});

export const {
    // company wise
	getCompanyWiseFail,
	getCompanyWiseRequest,
	getCompanyWiseSuccess,
    // category subtype wise
	getCategorySubTypeWiseFail,
	getCategorySubTypeWiseRequest,
	getCategorySubTypeWiseSuccess,
    // category wise
	getCategoryWiseFail,
	getCategoryWiseRequest,
	getCategoryWiseSuccess,
    // listing type wise
    getListingTypeWiseFail,
    getListingTypeWiseRequest,
    getListingTypeWiseSuccess,
    // sector wise
    getSectorWiseFail,
    getSectorWiseRequest,
    getSectorWiseSuccess,
    // user type wise
    getUserTypeWiseFail,
    getUserTypeWiseRequest,
    getUserTypeWiseSuccess,
    // sub category wise
    getSubCategoryWiseFail,
    getSubCategoryWiseRequest,
    getSubCategoryWiseSuccess,
    // status wise
    getStatusWiseFail,
    getStatusWiseRequest,
    getStatusWiseSuccess,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
