/** @format */
import { combineEpics } from "redux-observable";
import { userEpics } from "../Pages/CoreSetupPages/User/Redux/userEpic";
import { authEpics } from "../Pages/Login/Redux/authEpic";
import { organizationEpics } from "../Pages/CoreSetupPages/OrganizationSetup/Redux/organizationEpic";
import { userRoleEpics } from "../Pages/CoreSetupPages/UserRole/Redux/userRoleEpic";

import { sectorEpics } from "../Pages/CoreSetupPages/SectorSetup/Redux/sectorSetupEpic";
import { subCategoryEpics } from "../Pages/CoreSetupPages/SubCategory/Redux/subCategoryEpic";
import { categoryEpics } from "../Pages/CoreSetupPages/Category/Redux/categoryEpic";
import { categorySubTypeEpics } from "../Pages/CoreSetupPages/CategorySubType/Redux/categorySubTypeEpic";
import { sliderEpics } from "../Pages/CoreSetupPages/HomepageSlider/Redux/sliderEpic";
import { professionalEpics } from "../Pages/CoreSetupPages/ProfessionalSetup/Redux/professionalSetupEpic";
//common viewDetails
import { itemListDetailEpics } from "../Pages/ViewDetails/ItemList/Redux/ItemListDetailEpic";
import { companyEpics } from "../Pages/CoreSetupPages/Company/Redux/companyEpic";
import { contactEpics } from "../Pages/CoreSetupPages/ContactListing/Redux/contactEpic";
import { blogEpics } from "../Pages/CoreSetupPages/Blog/Redux/blogEpic";
import { blogTagEpics } from "../Pages/CoreSetupPages/BlogTag/Redux/tagEpic";
import { blogCategoryEpics } from "../Pages/CoreSetupPages/BlogCategory/Redux/categoryEpic";
import { investorProfileEpics } from "../Pages/CoreSetupPages/InvestorProfile/Redux/investorProfileEpic";
import { commonAppEpics } from "../Pages/CommonAppRedux/commonAppEpic";

//password
import { passwordEpics } from "../Pages/Password/Redux/passwordEpic";
import { notificationEpics } from "../Component/Notification/Redux/notificationEpic";
import { dashboardEpics } from "../Pages/CoreSetupPages/Dashboard/Redux/dashboardEpic";
import { publicUserEpics } from "../Pages/CoreSetupPages/PublicUserList/Redux/publicUserEpic";

export const RootEpic = combineEpics(
  userEpics,
  authEpics,
  categorySubTypeEpics,
  userRoleEpics,
  organizationEpics,
  subCategoryEpics,
  categoryEpics,
  sectorEpics,
  sliderEpics,
  professionalEpics,
  blogEpics,
  blogTagEpics,
  blogCategoryEpics,
  investorProfileEpics,
  //viewDetails
  itemListDetailEpics,
  // company
  companyEpics,
  // contact
  contactEpics,
  //password
  passwordEpics,
  commonAppEpics,
  // notification epics
  notificationEpics,
  // dashboard epics
  dashboardEpics,
  // public user epics
  publicUserEpics,
);
