import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import user from "../../../Assets/CoreSetup/User.webp";
import organization from "../../../Assets/CoreSetup/organization.webp";
// import user from "../../../../Assets/CoreSetup/User.webp";
import userRole from "../../../Assets/CoreSetup/userRole.webp";
export const masterSidebarData = [
  {
    name: "User Setup",
    Icon: AdminPanelSettingsIcon,
    items: [
      {
        name: "User",
        image: user,
        link: "/core-setup/user",
        permission: [
          "view_user",
          "add_user",
          "update_user",
          "change_user_password",
        ],
      },
      {
        name: "User Role",
        image: userRole,
        link: "/core-setup/user-group",
        permission: ["view_role", "add_role", "update_role"],
      },
    ],
  },
  {
    name: "Organization Info",
    Icon: AdminPanelSettingsIcon,
    items: [
      {
        name: "Organization Setup",
        image: organization,
        // Icon: BusinessTwoToneIcon,
        link: "/core-setup/organization-setup",
        permission: [
          "view_organization_setup",
          "add_organization_setup",
          "update_organization_setup",
          "delete_organization_setup",
        ],
      },
    ],
  },
  {
    name: "Dashboard",
    Icon: AdminPanelSettingsIcon,
    items: [
      {
        name: "Dashboard",
        image: organization,
        // Icon: BusinessTwoToneIcon,
        link: "/core-setup/dashboard",
        permission: [
          "view_dashboard",
          // "add_organization_setup",
          // "update_organization_setup",
          // "delete_organization_setup",
        ],
      },
    ],
  },
  {
    name: "Company Setup",
    Icon: AdminPanelSettingsIcon,
    items: [
      {
        name: "Category Setup",
        // image: organization,
        link: "/core-setup/category-setup",
        permission: ["view_category", "add_category", "update_category"],
      },
      {
        name: "Category Sub Type Setup",
        // image: organization,
        link: "/core-setup/category-sub-type-setup",
        permission: [
          "view_category_sub_type_setup",
          "add_category_sub_type_setup",
          "update_category_sub_type_setup",
          "delete_category_sub_type_setup",
        ],
      },
      {
        name: "Sub Category Setup",
        // image: organization,
        link: "/core-setup/sub-category-setup",
        permission: [
          "view_sub_category_setup",
          "add_sub_category_setup",
          "update_sub_category_setup",
          "delete_sub_category_setup",
        ],
      },
      {
        name: "Sector Setup",
        // image: organization,
        link: "/core-setup/sector-setup",
        permission: [
          "view_sector_setup",
          "add_sector_setup",
          "update_sector_setup",
          "delete_sector_setup",
        ],
      },
    ],
  },
  {
    name: "CMS Setup",
    Icon: AdminPanelSettingsIcon,
    items: [
      {
        name: "Homepage Slider Setup",
        // image: organization,
        link: "/core-setup/home-page-slider-setup",
        permission: ["view_homepage", "add_homepage", "update_homepage"],
      },
    ],
  },
  {
    name: "Professional Setup",
    Icon: AdminPanelSettingsIcon,
    items: [
      {
        name: "Professional Setup",
        // image: AdminPanelSettingsIcon,
        link: "/core-setup/professional-setup",
        permission: [
          "view_professional",
          "add_professional",
          "update_professional",
        ],
      },
    ],
  },
  {
    name: "Investor Profile",
    Icon: AdminPanelSettingsIcon,
    items: [
      {
        name: "Investor Profile",
        // image: AdminPanelSettingsIcon,
        link: "/core-setup/investor-profile",
        permission: [
          "view_investor_profile",
          "add_investor_profile",
          "update_investor_profile",
        ],
      },
    ],
  },
  {
    name: "Contact Request",
    Icon: AdminPanelSettingsIcon,
    items: [
      {
        name: "Contact Request",
        // image: AdminPanelSettingsIcon,
        link: "/core-setup/contact-request",
        permission: ["view_contact_form"],
      },
    ],
  },
  {
    name: "Public User List",
    Icon: AdminPanelSettingsIcon,
    items: [
      {
        name: "Public User",
        // image: organization,
        link: "/core-setup/public-user",
        permission: [
          "view_public_user_list",
          // "add_companies",
          // "update_companies",
          // "delete_companies",
        ],
      },
    ],
  },
  {
    name: "Company Listings",
    Icon: AdminPanelSettingsIcon,
    items: [
      {
        name: "Companies",
        // image: organization,
        link: "/core-setup/companies",
        permission: [
          "view_companies",
          "add_companies",
          "update_companies",
          "delete_companies",
        ],
      },
    ],
  },
  {
    name: "Blog",
    Icon: AdminPanelSettingsIcon,
    items: [
      {
        name: "Blog",
        // image: AdminPanelSettingsIcon,
        link: "/core-setup/blog",
        permission: [""],
      },
      {
        name: "Blog Tag",
        // image: AdminPanelSettingsIcon,
        link: "/core-setup/blog-tag",
        permission: [""],
      },
      {
        name: "Blog Category",
        // image: AdminPanelSettingsIcon,
        link: "/core-setup/blog-category",
        permission: [""],
      },
    ],
  },
];
