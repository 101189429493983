// import { permission } from "../Redux/Auth/types";

export const handlePermission = (
  permissions: any[],
  modulePermission: string
) => {
  let permission = permissions?.some(
    ({ code_name }: any) => code_name === modulePermission
  );
  return permission;
};
