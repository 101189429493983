import {
  getCategorySubTypeSuccess,
  getCategorySubTypeFail,
  loadingCategorySubType,
  getCategorySubTypeRequest,
  getCategorySubTypeNextRequest,
  getCategorySubTypePreviousRequest,
  //
  updateCategorySubTypeRequest,
  updateCategorySubTypeSuccess,
  updateCategorySubTypeFail,
  //
  getCategorySubTypeByIdRequest,
  editCategorySubTypeSuccess,
  editCategorySubTypeFail,

  clearCategorySubTypeData,
} from "./categorySubTypeSlice";
import { mergeMap } from "rxjs";
import { getCategorySubType, getCategorySubTypeById, updateCategorySubType } from "./api";
import { map, filter} from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import { alertErrorAction, alertSuccessAction, closeModal } from "../../../CommonAppRedux/CommonAppSlice";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get CategorySubType
const getCategorySubTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCategorySubTypeRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingCategorySubType());
      try {
        const response = await getCategorySubType(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getCategorySubTypeSuccess(action?.payload) : getCategorySubTypeFail()
    )
  );

//get categorySubType by id
const getCategorySubTypeByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCategorySubTypeByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getCategorySubTypeById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? editCategorySubTypeSuccess(action?.payload) : editCategorySubTypeFail()
    )
  );

//get next
const getCategorySubTypeNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCategorySubTypeNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getCategorySubTypeSuccess(action?.payload) : getCategorySubTypeFail()
    )
  );

//get previous
const getCategorySubTypePrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCategorySubTypePreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getCategorySubTypeSuccess(action?.payload) : getCategorySubTypeFail()
    )
  );

//update categorySubType epic
const updateCategorySubTypeEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateCategorySubTypeRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }
        const response = await updateCategorySubType(body, id);
        if (response) {
          dispatch(getCategorySubTypeRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearCategorySubTypeData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateCategorySubTypeSuccess() : updateCategorySubTypeFail()
    )
  );

export const categorySubTypeEpics = combineEpics(
  getCategorySubTypeEpic,
  getCategorySubTypeNext,
  getCategorySubTypePrevious,
  getCategorySubTypeByIdEpic,
  updateCategorySubTypeEpic,
);
