import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const companyModURL = "/api/v1/company-app/company";

//get Category data api
export const getCompany = (data: getApiProps) => {
  const { rowsPerPage, page, id } = data;
  return axiosInstance.get(
    `${companyModURL}?${id ? `category=${id}` : ""}&offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//handle search
export const searchCompany = ({ rowsPerPage, search }: searchProps) => {
  return `${companyModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create Category
// export const createCategory = (body: string | FormData) =>
//   axiosInstance.post(`${categoryModURL}`, body);
//update company
export const updateCompany = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${companyModURL}-update/${id}`, body);
// get company details
export const getCompanyDetails = (id: number) =>{
 return axiosInstance.get(`${companyModURL}/${id}`);
}
//update status
export const updateStatus = (body: string | FormData, id: number) =>
  axiosInstance.patch(`api/v1/company-app/verify-valuate-company/${id}`, body);
// get company by id
export const getCompanyById = (id: number) => {
  return axiosInstance.get(`${companyModURL}/${id}`);
};
//get img by id
export const getImageById = (id: number) => {
  return axiosInstance.get(`api/v1/company-app/documents-get?document=${id}`, {
    responseType: "blob",
  });
};
//get company category
export const getCompanyCategory = () => {
  return axiosInstance.get("api/v1/company-app/category-list");
};
//get company category subtype
export const getCompanyCategorySubType = () => {
  return axiosInstance.get("api/v1/company-app/category-subtype-list");
};
//get company sub category
export const getCompanySubCategory = () => {
  return axiosInstance.get("api/v1/company-app/sub-category-list");
};
//get company sector
export const getCompanySector = () => {
  return axiosInstance.get("api/v1/company-app/sector-list");
};
