import { combineReducers } from "@reduxjs/toolkit";
import CommonAppSlice from "../Pages/CommonAppRedux/CommonAppSlice";
import User from "../Pages/CoreSetupPages/User/Redux/userSlice";
import Auth from "../Pages/Login/Redux/authSlice";
import Sector from "../Pages/CoreSetupPages/SectorSetup/Redux/sectorSetupSlice";
import CategorySubType from "../Pages/CoreSetupPages/CategorySubType/Redux/categorySubTypeSlice";
import Category from "../Pages/CoreSetupPages/Category/Redux/categorySlice";
import SubCategory from "../Pages/CoreSetupPages/SubCategory/Redux/subCategorySlice";
import Slider from "../Pages/CoreSetupPages/HomepageSlider/Redux/sliderSlice";
import Professional from "../Pages/CoreSetupPages/ProfessionalSetup/Redux/professionalSetupSlice";
import UserRole from "../Pages/CoreSetupPages/UserRole/Redux/userRoleSlice";
import Organization from "../Pages/CoreSetupPages/OrganizationSetup/Redux/organizationSlice";
// clinic reducers
import PaymentDetails from "../Pages/CommonAppPages/PaymentModeDetails/Redux/paymentDetailsSlice";
//viewDetails
import ItemListDetail from "../Pages/ViewDetails/ItemList/Redux/ItemListDetailSlice";
import Company from "../Pages/CoreSetupPages/Company/Redux/companySlice";
import Contact from "../Pages/CoreSetupPages/ContactListing/Redux/contactSlice";
import Blog from "../Pages/CoreSetupPages/Blog/Redux/blogSlice";
import BlogTag from "../Pages/CoreSetupPages/BlogTag/Redux/tagSlice";
import BlogCategory from "../Pages/CoreSetupPages/BlogCategory/Redux/categorySlice";
import InvestorProfile from "../Pages/CoreSetupPages/InvestorProfile/Redux/investorProfileSlice";
//password
import Password from "../Pages/Password/Redux/passwordSlice";
// Notification
import NotificationReducer from '../Component/Notification/Redux/notificationSlice';
// Dashboard
import DashboardReducer from '../Pages/CoreSetupPages/Dashboard/Redux/dashboardSlice';
// public user
import PublicUserReducer from '../Pages/CoreSetupPages/PublicUserList/Redux/publicUserSlice'
import CropperToolReducer from '../Component/CropperTool/Redux/cropperToolSlice'
export const rootReducer = combineReducers({
  // master
  commonApp: CommonAppSlice,
  user: User,
  auth: Auth,
  userRole: UserRole,
  organization: Organization,
  paymentDetail: PaymentDetails,
  sector: Sector,
  categorySubType: CategorySubType,
  category: Category,
  subCategory: SubCategory,
  slider: Slider,
  professional: Professional,
  blog: Blog,
  blogTag: BlogTag,
  blogCategory: BlogCategory,
  //viewDetails
  itemListDetail: ItemListDetail,
  company: Company,
  // contact
  contact: Contact,
  //password
  password: Password,
  investorProfile: InvestorProfile,
  // notification
  notification: NotificationReducer,
  // dashboard
  dashboard: DashboardReducer,
  // Public user list
  publicUser: PublicUserReducer,
  // cropper tool
  cropper: CropperToolReducer,
});
