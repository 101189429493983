import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const categorySubTypeModURL = "api/v1/category-app/category-subtype";

//get Category data api
export const getCategorySubType = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${categorySubTypeModURL}?offset=${rowsPerPage * page}&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get Category by Id
export const getCategorySubTypeById = (id: number) => {
  return axiosInstance.get(`${categorySubTypeModURL}/${id}`);
}

//handle search
export const searchCategorySubType = ({ rowsPerPage, search }: searchProps) => {
  return `${categorySubTypeModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create Category
// export const createCategory = (body: string | FormData) =>
//   axiosInstance.post(`${categorySubTypeModURL}`, body);

//update Category
export const updateCategorySubType = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${categorySubTypeModURL}/${id}`, body);
