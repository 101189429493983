import {
  getSubCategorySuccess,
  getSubCategoryFail,
  loadingSubCategory,
  getSubCategoryRequest,
  //
  getSubCategoryNextRequest,
  getSubCategoryPreviousRequest,
  //
  clearSubCategoryData,
  //
  updateSubCategoryRequest,
  updateSubCategorySuccess,
  updateSubCategoryFail,
  //
  getSubCategoryByIdRequest,
  editSubCategorySuccess,
  editSubCategoryFail,
  viewSubCategoryByIdRequest,
  viewSubCategorySuccess,
  viewSubCategoryFail,
} from "./subCategorySlice";
import { mergeMap } from "rxjs";
import {
  getSubCategory,
  getSubCategoryById,
  updateSubCategory,
  viewSubCategoryById,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get SubCategory
const getSubCategoryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSubCategoryRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingSubCategory());
      try {
        const response = await getSubCategory(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSubCategorySuccess(action?.payload)
        : getSubCategoryFail()
    )
  );

//get subCategory by id
const getsubCategoryByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSubCategoryByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getSubCategoryById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? editSubCategorySuccess(action?.payload)
        : editSubCategoryFail()
    )
  );

//view sub category by id
const viewSubCategoryByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(viewSubCategoryByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await viewSubCategoryById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? viewSubCategorySuccess(action?.payload)
        : viewSubCategoryFail()
    )
  );

//get next
const getSubCategoryNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSubCategoryNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSubCategorySuccess(action?.payload)
        : getSubCategoryFail()
    )
  );

//get previous
const getSubCategoryPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getSubCategoryPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? getSubCategorySuccess(action?.payload)
        : getSubCategoryFail()
    )
  );
//update category epic
const updateSubCategoryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateSubCategoryRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      const { name, is_featured, remarks, image, is_active } = values;
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }
        // if(image) {
        //   body.append("image", image)
        // }
        // body.append("name", name);
        // body.append("is_featured", is_featured);
        // body.append("is_active", is_active);
        // body.append("remarks", remarks);
        const response = await updateSubCategory(body, id);
        if (response) {
          dispatch(getSubCategoryRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearSubCategoryData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateSubCategorySuccess() : updateSubCategoryFail()
    )
  );
export const subCategoryEpics = combineEpics(
  getSubCategoryEpic,
  getSubCategoryNext,
  getSubCategoryPrevious,
  updateSubCategoryEpic,
  getsubCategoryByIdEpic,
  viewSubCategoryByIdEpic
);
