import { axiosInstance } from "../../../../AppUtils/Utils/axios";
import { filterApiData } from "../../../../AppUtils/Utils/appFunctions";
import {
  getApiProps,
  searchProps,
} from "../../../../AppUtils/Utils/globalTypes";

export const categoryModURL = 'api/v1/blog-app/category';

//get Category data api
export const getCategory = (data: getApiProps) => {
  const { rowsPerPage, page } = data;
  return axiosInstance.get(
    `${categoryModURL}?offset=${
      rowsPerPage * page
    }&limit=${rowsPerPage}${
      data?.filterData ? filterApiData(data?.filterData) : ""
    }`
  );
};

//get Category by Id
export const getCategoryById = (id: number) => {
  return axiosInstance.get(`${categoryModURL}/${id}`);
}

//handle search
export const searchCategory = ({ rowsPerPage, search }: searchProps) => {
  return `${categoryModURL}?offset=0&limit=${rowsPerPage}&search=${search}`;
};
//create Category
export const createCategory = (body: string | FormData) =>
  axiosInstance.post(`${categoryModURL}`, body);
//update Category
export const updateCategory = (body: string | FormData, id: number) =>
  axiosInstance.patch(`${categoryModURL}/${id}`, body);
