import React, { Suspense } from "react";
import { Redirect } from "react-router-dom";
import { Switch } from "react-router-dom";
import CoreSetupRoutes from "./CoreSetupRoutes";
// lab components imports
const PrivateRoutes = () => {
  return (
    <>
      {/* <ErrorBoundary FallbackComponent={ErrorHandler}> */}
      <Suspense fallback={<></>}>
        {/* <ProtectedRoutes exact path="/" component={Profile} permission="" /> */}
        <CoreSetupRoutes />
        <Switch>
          <Redirect from="/" to="/" exact />
          {/* <Route path="*" component={Errorpage} /> */}
        </Switch>
      </Suspense>
      {/* </ErrorBoundary> */}
    </>
  );
};

export default PrivateRoutes;
