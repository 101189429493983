import React, {
  memo,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import { items, sideBarData } from "../AppPage/types";
import { useAppSelector } from "../../AppUtils/Utils/appHooks";
import { commonAppSelector } from "../../Pages/CommonAppRedux/selector";
import { loginSelector } from "../../Pages/Login/Redux/selector";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    tabIndex: -1,
    "aria-selected": undefined,
  };
}
interface tabs {
  dataToBeLoad: items[];
}
const AppTabs = ({ dataToBeLoad }: tabs) => {
  const { selectedModuleIndex } = useAppSelector(commonAppSelector);
  let history = useHistory();
  const [value, setValue] = useState(selectedModuleIndex);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const tabData = useMemo(() => {
    return dataToBeLoad?.filter(({ items }: any) => {
      return items.some(({ link }: any) => {
        return link === history?.location?.pathname;
      });
    });
  }, [dataToBeLoad, history?.location?.pathname]);
  useEffect(() => {
    setValue(selectedModuleIndex);
  }, [selectedModuleIndex, history?.location?.pathname]);
  const { permissions, is_superuser } = useAppSelector(loginSelector);
  return (
    <Box className="tabs-wrapper">
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        TabIndicatorProps={{
          sx: { background: "none" },
        }}
      >
        {tabData?.map((loadedTabData: any) => {
          const { items } = loadedTabData;
          return items?.map((tabToBeLoad: any, index: number) => {
            // const { name, link } = tabToBeLoad;
            const { name, link, permission } = tabToBeLoad;
            let showSubMenu = permissions?.some((element: any) =>
              permission?.includes(element.code_name)
            );

            return is_superuser || showSubMenu ? (
              <Tab
                label={name}
                {...a11yProps(index)}
                key={index}
                to={link}
                component={Link}
              />
            ) : null;
          });
        })}
      </Tabs>
    </Box>
  );
};
export default memo(AppTabs);
