import { createSlice } from "@reduxjs/toolkit";
import { initialStateCategory } from "./types";
const initialState: initialStateCategory = {
  categories: [],
  loadingCategory: false,
  // edit
  edit: false,
  category: null,
  loadingUpdateCategory: false,
  count: 0,
  previous: "",
  next: "",
};

export const Category = createSlice({
  name: "CategoryReducer",
  initialState,
  reducers: {
    loadingCategory: (state) => {
      state.loadingCategory = true;
    },
    getCategoryRequest: (state, payload) => {
      state.loadingCategory = true;
    },
    getCategorySuccess: (
      state,
      { payload: { results, count, next, previous } }
    ) => {
      state.loadingCategory = false;
      state.categories = results;
      state.count = count;
      state.next = next;
      state.previous = previous;
    },
    getCategoryFail: (state) => {
      state.loadingCategory = false;
    },
    getCategoryNextRequest: (state, payload) => {
      state.loadingCategory = true;
    },
    getCategoryPreviousRequest: (state, payload) => {
      state.loadingCategory = true;
    },
    //
    getCategoryByIdRequest: (state, payload) => {
      state.edit = true;
    },
    editCategorySuccess: (state, { payload }) => {
      state.category = payload;
    },
    editCategoryFail: (state) => {
      state.edit = false;
    },
    //
    viewCategoryByIdRequest: (state, payload) => {
      state.loadingCategory = true;
    },
    viewCategorySuccess: (state, { payload }) => {
      state.category = payload;
      state.loadingCategory = false;
    },
    viewCategoryFail: (state) => {
      state.loadingCategory = false;
    },
    //update
    updateCategoryRequest: (state, payload) => {
      state.loadingUpdateCategory = true;
    },
    updateCategorySuccess: (state) => {
      state.loadingUpdateCategory = false;
    },
    updateCategoryFail: (state) => {
      state.loadingUpdateCategory = false;
    },
    clearCategoryData: (state) => {
      state.category = null;
      state.edit = false;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  loadingCategory,
  getCategorySuccess,
  getCategoryFail,
  getCategoryRequest,
  getCategoryNextRequest,
  getCategoryPreviousRequest,
  //
  clearCategoryData,
  // edit
  getCategoryByIdRequest,
  editCategorySuccess,
  editCategoryFail,
  //
  updateCategoryRequest,
  updateCategorySuccess,
  updateCategoryFail,
  // view
  viewCategoryByIdRequest,
  viewCategorySuccess,
  viewCategoryFail,
} = Category.actions;
export default Category.reducer;
