import { createSlice } from '@reduxjs/toolkit';
import { initialPublicUserListProps } from './types';

const initialState: initialPublicUserListProps = {
	publicUserList: [],
	publicUser: null,
	loadingPublicUserList: false,
	count: 0,
	next: '',
	previous: '',
	loadingPublicUser: false,
	viewDetails: false,
};

const publicUserListSlice = createSlice({
	name: 'public user',
	initialState,
	reducers: {
		getPublicUserListRequest: (state, action) => {
			state.loadingPublicUserList = true;
		},
		getPublicUserListSuccess: (
			state,
			{ payload: { results, next, count, previous } }
		) => {
			state.loadingPublicUserList = false;
			state.count = count;
			state.previous = previous;
			state.next = next;
			state.publicUserList = results;
		},
		getPublicUserListFail: (state) => {
			state.loadingPublicUserList = false;
		},
		viewPublicUserByIdRequest: (state, payload) => {
			state.loadingPublicUser = true
			// state.viewDetails = true;
		},
		viewPublicUserByIdSuccess: (state, { payload: {results} }) => {
			state.loadingPublicUser = false
			state.publicUser = results;
		},
		viewPublicUserByIdFail: (state) => {
			state.loadingPublicUser = false
			// state.viewDetails = false;
		},
		getPublicUserNextRequest: (state, payload) => {
			state.loadingPublicUserList = true;
		},
		getPublicUserPreviousRequest: (state, payload) => {
			state.loadingPublicUserList = true;
		},
		// clear
		clearPublicUserData: (state) => {
			state.publicUser = null;
			state.viewDetails = false;
		},
	},
});

export const {
	clearPublicUserData,
	// get public user list
	getPublicUserListRequest,
	getPublicUserListFail,
	getPublicUserListSuccess,
	// view public user details
	viewPublicUserByIdRequest,
	viewPublicUserByIdFail,
	viewPublicUserByIdSuccess,
	// pagination
	getPublicUserNextRequest,
	getPublicUserPreviousRequest,
} = publicUserListSlice.actions;

export default publicUserListSlice.reducer;
