import {
  getCategorySuccess,
  getCategoryFail,
  loadingCategory,
  getCategoryRequest,
  getCategoryNextRequest,
  getCategoryPreviousRequest,
  clearCategoryData,
  updateCategoryRequest,
  updateCategorySuccess,
  updateCategoryFail,
  getCategoryByIdRequest,
  editCategorySuccess,
  editCategoryFail,
  viewCategoryByIdRequest,
  viewCategorySuccess,
  viewCategoryFail,
} from "./categorySlice";
import { mergeMap } from "rxjs";
import {
  getCategory,
  getCategoryById,
  updateCategory,
  viewCategoryById,
} from "./api";
import { map, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { Action } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import { getNext, getPrevious } from "../../../CommonAppRedux/api";
import {
  dispatchAction,
  stateAction,
} from "../../../../AppUtils/Utils/globalTypes";
import {
  alertErrorAction,
  alertSuccessAction,
  closeModal,
} from "../../../CommonAppRedux/CommonAppSlice";
import messages from "../../../../AppUtils/Utils/validationConstants";

export const controller = new AbortController();

// get Category
const getCategoryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCategoryRequest.match),
    mergeMap(async (action) => {
      dispatch(loadingCategory());
      try {
        const response = await getCategory(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getCategorySuccess(action?.payload) : getCategoryFail()
    )
  );

//get category by id
const getCategoryByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCategoryByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getCategoryById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? editCategorySuccess(action?.payload)
        : editCategoryFail()
    )
  );

//view category by id
const viewCategoryByIdEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(viewCategoryByIdRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await viewCategoryById(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload
        ? viewCategorySuccess(action?.payload)
        : viewCategoryFail()
    )
  );

//get next
const getCategoryNext = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCategoryNextRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getNext(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getCategorySuccess(action?.payload) : getCategoryFail()
    )
  );

//get previous
const getCategoryPrevious = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(getCategoryPreviousRequest.match),
    mergeMap(async (action) => {
      try {
        const response = await getPrevious(action.payload);
        return { payload: response.data };
      } catch (e) {
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? getCategorySuccess(action?.payload) : getCategoryFail()
    )
  );
//update category epic
const updateCategoryEpic = (
  action$: Observable<Action>,
  _: stateAction,
  { dispatch }: dispatchAction
) =>
  action$.pipe(
    filter(updateCategoryRequest.match),
    mergeMap(async ({ payload: { values, id, rowsPerPage, page, search } }) => {
      try {
        const body = new FormData();
        for (let [key, value] of Object.entries(values)) {
          // @ts-ignore
          body.append(`${key}`, value);
        }

        const response = await updateCategory(body, id);
        if (response) {
          dispatch(getCategoryRequest({ rowsPerPage, page, search }));
          dispatch(alertSuccessAction(messages.updateMessage));
          dispatch(closeModal());
          dispatch(clearCategoryData());
        }
        return { payload: { response, rowsPerPage } };
      } catch (e) {
        dispatch(alertErrorAction(messages.updateFailMessage));
        return { error: e };
      }
    }),
    map((action) =>
      action?.payload ? updateCategorySuccess() : updateCategoryFail()
    )
  );
export const categoryEpics = combineEpics(
  getCategoryEpic,
  getCategoryNext,
  getCategoryPrevious,
  updateCategoryEpic,
  getCategoryByIdEpic,
  viewCategoryByIdEpic
);
